import { Nullable } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { performance } from 'universal-perf-hooks';
import OTelService from '@/services/serverless/integrations/OTelService/OTelService';
import { Span } from '@opentelemetry/api';
import ConfigurationService from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';

/** Represents a performance timing measurement. */
export default class Measure {
  private _startTime: Nullable<number> = null;
  private _total: Nullable<number> = null;
  private _completeTime: Nullable<number> = null;
  private _span: Nullable<Span> = null;
  private readonly enableOTel: boolean;

  /**
   * @param whatsBeingMeasured - A string describing what is being measured.
   * @inheritdoc
   * */
  public constructor(private readonly whatsBeingMeasured: string) {
    this.enableOTel =
      ConfigurationService.getConfig('otel').getSetting('enabled').value;
  }

  /** Get the total time of the measurement.
   * @returns The numeric total of the measurement in milliseconds or `null` if not complete.
   * */
  public get total(): Nullable<number> {
    return this._total;
  }

  /** The time stamp at which the measure started.
   * @returns The start time.
   * */
  public get startTime(): Nullable<number> {
    return this._startTime;
  }

  /** The time stamp at which the measure completed.
   * @returns The completed time.
   * */
  public get completeTime(): Nullable<number> {
    return this._completeTime;
  }

  /**
   * Begins the measurement.
   * @throws `InvalidStateError` if `.start()` is called more than once before calling `.complete()`.
   * */
  public start(): void {
    // If there is already a start time, but `.complete()` was never called.
    if (this._startTime && !this._total) {
      throw new InvalidStateError(
        'Method `.start()` was called twice before calling `.complete()` first.'
      );
    }
    this._startTime = performance.now();

    /**
     * If OTel is enabled and the environment is on the server,
     * call the OTelService to start the span measurement.
     * */
    if (this.enableOTel && (typeof window === "undefined")) {
      this._span = OTelService.startSpan(this.whatsBeingMeasured, null);
    }
  }

  /**
   * Completes the measurement.
   * @throws `InvalidStateError` if `.complete()` is called before calling `.start()`.
   * @returns The number of milliseconds the measurement took.
   * */
  public complete(): number {
    if (!this._startTime) {
      throw new InvalidStateError(
        'Method `.complete()` was called before calling `.start()` first.'
      );
    }
    this._completeTime = performance.now();
    this._total = this._completeTime - this._startTime;

    /**
     * If OTel is enabled and the environment is on the server,
     * call the OTelService to end the span measurement.
     * */
    if (this.enableOTel && (typeof window === "undefined")) {
      /**
       * Throw an error if span isn't started before complete method is called.
       * */
      if (!this._span) {
        throw new InvalidStateError(
          'Method `.complete()` was called before calling OTelService.startSpan().'
        );
      }
      OTelService.endSpan(this._span);
    }

    return this._total;
  }

  /**
   * Restarts a measurement regardless of whether it was previously started or completed.
   * */
  public restart(): void {
    this._total = null;
    this._completeTime = null;
    this._startTime = null;
    if (this.enableOTel && this._span) {
      OTelService.endSpan(this._span);
      this._span = null;
    }
    this.start();
  }
}
