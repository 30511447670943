import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { UnableToAccessResourceError } from '@/utils/errors';

export const { UnableToAccessAccountError } = errorFactory(
  'UnableToAccessAccountError',
  UnableToAccessResourceError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/**
 * Error to use when a user's account could not be accessed, not necessarily because it could
 * not be found, but for any unexpected reasons.
 */
export type UnableToAccessAccountError = InstanceTypeOf<
  typeof UnableToAccessAccountError
>;
