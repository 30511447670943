import { validate as validateUUID } from 'uuid';
import { ZodType, z } from 'zod';
import IUser from './IUser';
import UserType from './UserType';

/**
 * Zod schema to validate an IUser object.
 */
export const IUserSchema = z
  .object({
    type: z.nativeEnum(UserType),
    // we use `validateUUID` instead of `.uuid()` to ensure
    // our UUID validation is consistent across the platform
    uuid: z.string().refine(validateUUID)
  })
  // we allow the passthrough of properties in case the model is a subtype of IUser
  .passthrough() as ZodType<IUser>;
