import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { MiddlewareExecutionError } = errorFactory(
  'MiddlewareExecutionError'
);

/** Error to use when a middleware fails to execute. */
export type MiddlewareExecutionError = InstanceTypeOf<
  typeof MiddlewareExecutionError
>;
