import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';

export const { UnableToDetermineSessionIDError } = errorFactory(
  'UnableToDetermineSessionIDError',
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/** Error to use when the session ID cannot be determined for an unknown reason. */
export type UnableToDetermineSessionIDError = InstanceTypeOf<
  typeof UnableToDetermineSessionIDError
>;
