import { USER_ID_KEY } from '@/configs/env/public';
import CookieService from '@/services/isomorphic/CookieService';
import LoggerService from '@/services/isomorphic/LoggerService';
import {
  CookieModel,
  CookieNotFoundError,
  SameSite
} from '@/services/models/Cookie';
import { ISession, SessionModel } from '@/services/models/Session';
import {
  AuthenticatedUserModel,
  IAuthenticatedUser,
  IAuthenticatedUserTokens
} from '@/services/models/User/AuthenticatedUser';
import { DTO, Nullable } from '@/type-utils';
import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';
import { v4 as uuidv4, validate as validateUUID } from 'uuid';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import type { IAccount } from '@/services/models/Account';
import Service from '../../Service';
import UserService from '../../isomorphic/UserService';
import { UserModel, type IUser } from '../../models/User';
import type { IAnonymousUser } from '../../models/User/AnonymousUser';
import UserType from '../../models/User/UserType';
import { IUserSchema } from '../../models/User/schemas';

import ServerSessionService from '../ServerSessionService';
import ServerCognitoService from '../integrations/ServerCognitoService';
import { CognitoAccessTokenExpiredError } from '../integrations/ServerCognitoService/errors/CognitoAccessTokenExpiredError';
import { InvalidCognitoAccessTokenError } from '../integrations/ServerCognitoService/errors/InvalidCognitoAccessTokenError';
import ServerAccountService from '../ServerAccountService';

/**
 * Abstracts server-only operations related to User Authentication.
 *
 * For more general operations, check the isomorphic {@link UserService}.
 */
export class ServerUserService extends Service {
  /**
   * The key used to store the current user in the session.
   *
   * It is private because it should only be used internally.
   * In other words, all changes to the current user should
   * go through this class; it is the sole authority on the current user.
   */
  private static USER_SESSION_KEY = 'currentUser';

  /**
   * Gets the current user from the current session.
   * If the user isn't in the session, creates a new anonymous user
   * from the user ID cookie, saves it to the session, and then returns it.
   * @param [session] - The session to get the user from.
   * Useful for reusing session data from a previous request.
   * @returns The current user.
   * @throws A {@link CookieNotFoundError} if the user ID cookie is not present.
   * @throws An {@link InvalidStateError} if the user ID cookie does not match the session data.
   */
  public async getCurrentUser(session?: ISession): Promise<DTO<IUser>> {
    const sessionModel = session
      ? SessionModel.from(session)
      : await ServerSessionService.currentSession;

    const userDTOStr = sessionModel.data.tryGet(
      ServerUserService.USER_SESSION_KEY
    );

    if (userDTOStr) {
      try {
        const parsedUser = IUserSchema.parse(JSON.parse(userDTOStr));

        const userID = this.getUserID();
        if (parsedUser.uuid !== userID) {
          throw new InvalidStateError(
            `User ID cookie does not match session data. User ID cookie: ${userID}. Session user ID: ${parsedUser.uuid}`
          );
        }

        return parsedUser;
      } catch (e) {
        /**
         * Shouldn't happen. Some string is stored in the `currentUser` session
         * but it's either not JSON, or the `UserModel` is rejecting to
         * instantiate given that DTO, probably a malformed DTO,
         * or just a versioning issue.
         *
         * Log this error, but refresh the user anyway.
         * This will create a new anonymous user from the user ID cookie,
         * which will log the user out.
         */

        // Only log the malformed DTO on the lower envs to prevent PII leaks.
        const shouldLogDTO = !(process.env.NEXT_PUBLIC_APP_ENV === "prod");

        const error = new InvalidStateError(
          'Could not get the current user. String representing the current' +
          ' user is either not a valid JSON or not a valid user.' +
          shouldLogDTO
            ? ` DTO String: "${userDTOStr}"`
            : '',
          { cause: e }
        );
        LoggerService.error(error);

        return this._refreshUser();
      }
    }

    return this._refreshUser();
  }

  /**
   * Gets the current user ID.
   * @returns The current user ID.
   * @throws A {@link CookieNotFoundError} if the user ID cookie is not present.
   * @throws An {@link InvalidStateError} if the user ID cookie is not a valid UUID.
   */
  public getUserID(): string {
    const { value } = this._getUserIDCookie();

    // our middleware should ensure that the user ID cookie is always a valid UUID
    // but we'll check anyways just in case
    if (!validateUUID(value)) {
      throw new InvalidStateError(
        `User ID cookie is not a valid UUID. User ID cookie: ${value}`
      );
    }

    return value;
  }

  /**
   * Refreshes the current user using the current request's user ID cookie,
   * and saves it to the current session.
   *
   * Note 1: this will not update the user ID cookie.
   *
   * Note 2: the user will be anonymous, therefore this may log the client out.
   * However, this should be fine as this method should only be called when first
   * instantiating the user for the session, or if something went wrong retrieving
   * the user from the session.
   *
   * @returns The refreshed user.
   * @throws A {@link CookieNotFoundError} if the user ID cookie is not present.
   */
  private async _refreshUser(): Promise<DTO<IUser>> {
    const userID = this.getUserID();
    const user = this._createAnonUserWithID(userID);
    await this._saveCurrentUser(user);

    return user;
  }

  /**
   * Creates a user model from the current request's user ID cookie.
   * @param uuid - The user ID to use for the new user.
   * @returns A user model based on the current request's user ID cookie.
   * @throws A {@link CookieNotFoundError} if the user ID cookie is not present.
   */
  private _createAnonUserWithID(uuid: string): DTO<IUser> {
    const user: DTO<IAnonymousUser> = {
      type: UserType.Anonymous,
      uuid
    };

    return user;
  }

  /**
   * Resets the current user to a new anonymous user,
   * saves it to the current session, and updates the user ID cookie.
   * @returns The new user.
   */
  public async resetUser(): Promise<DTO<IUser>> {
    const newUserUUID = uuidv4();
    const user = this._createAnonUserWithID(newUserUUID);
    await this._saveCurrentUser(user);

    return user;
  }

  /**
   * Gets the current user ID cookie from the current request. This method also ensures
   * that the cookie is configured with the same attributes that are set in the middleware.
   * @returns The current user ID.
   * @throws A {@link CookieNotFoundError} if the user ID cookie is not present.
   */
  private _getUserIDCookie(): CookieModel {
    const baseCookie = CookieService.get(USER_ID_KEY);
    return CookieModel.from({
      ...baseCookie.toDTO(),
      path: '/',
      sameSite: SameSite.Lax,
      httpOnly: true
    });
  }

  /**
   * Saves the supplied user to the current {@link SessionService Session},
   * and updates the user ID cookie if necessary.
   *
   * @param user - User to store in session. Must be a {@link UserModel}.
   * @returns A promise that resolves on success.
   */
  public async saveCurrentUser(user: DTO<IUser>): Promise<void> {
    if (!validateUUID(user.uuid)) {
      throw new InvalidArgumentError(
        'Cannot save user to session: user ID is not a valid UUID.'
      );
    }

    await this._saveCurrentUser(user);
  }

  /**
   * Saves the supplied user to the current {@link SessionService Session},
   * and updates the user ID cookie if necessary.
   *
   * Note: since carts are tied to the user, updating the user ID will
   * also reset the cart.
   *
   * This method skips validations and should only be used internally.
   *
   * @param user - User to store in session. Must be a {@link UserModel}.
   * @returns A promise that resolves on success.
   */
  private async _saveCurrentUser(user: DTO<IUser>): Promise<void> {
    const dtoString = JSON.stringify(user);

    const userIDCookie = this._getUserIDCookie();

    // make sure the user ID cookie is synced with the session user data
    if (user.uuid !== userIDCookie.value) {
      CookieService.set(
        CookieModel.from({
          ...userIDCookie.toDTO(),
          value: user.uuid
        })
      );
    }

    // Note: although the cart is tied to the user, we don't need to reset the cart
    // because our client-side error handling will automatically initialize a new cart
    await ServerSessionService.setSessionData(
      ServerUserService.USER_SESSION_KEY,
      dtoString
    );
  }

  /**
   * Determines if a given {@link AuthenticatedUserModel authenticated user} is properly authorized or not.
   * That is, if their current access token is valid.
   *
   * @param user - The user to check authorization status for.
   * @param attemptRefresh - If `true`, the user's current refresh token will
   * be used to refresh {@link IAuthenticatedUserTokens the other tokens} in case the current access token is
   * invalid. Will fail if the current refresh token is invalid.
   *
   * @returns An {@link IAuthenticatedUser} if the user is already authorized or was
   * successfully reauthorized, or `null` if the user is not authorized.
   */
  public async assertIsAuthorized(
    user: IAuthenticatedUser,
    attemptRefresh = true
  ): Promise<Nullable<IAuthenticatedUser>> {
    const {
      uuid,
      tokens: { accessToken }
    } = user;

    try {
      const tokenPayload =
        await ServerCognitoService.verifyAccessToken(accessToken);

      if (!tokenPayload || tokenPayload.sub !== uuid) {
        return null;
      }

      return user;
    } catch (error) {
      if (error instanceof CognitoAccessTokenExpiredError && attemptRefresh) {
        const newUser = await UserService.refreshUserAuthorization(user);
        return newUser;
      }

      if (error instanceof InvalidCognitoAccessTokenError) {
        return null;
      }

      throw error;
    }
  }

  /**
   * Safely tries to get an {@link AuthenticatedUserModel} with valid tokens for the
   * current user.
   *
   * If this method does return a model, it can be said that the current user is
   * both _authenticated_ (i.e. logged in), and _authorized_ (i.e. has valid
   * access tokens). Therefore, this can be a quick and easy way to gatekeep
   * sensitive data.
   *
   * @param [session] - The session to get the user from. Useful for reusing session data from a previous request.
   * @returns An {@link AuthenticatedUserModel} if available, or `null` if not.
   */
  public async tryGetAuthorizedUser(
    session?: ISession | undefined
  ): Promise<Nullable<DTO<IAuthenticatedUser>>> {
    const currentUser = await this.getCurrentUser(session);

    try {
      const authorizedUser = UserService.isAuthenticated(currentUser)
        ? await this.assertIsAuthorized(currentUser, true)
        : null;

      return authorizedUser;
    } catch (error) {
      LoggerService.error(
        new Error('Error while trying to get authorized user.', {
          cause: error
        })
      );
      return null;
    }
  }

  /**
   * Gets the updated account for the current user.
   * If they are not logged in this will be functionally the same
   * as {@link getCurrentUser}.
   * If they are logged in, this will update the account information in the session
   * as well.
   * @returns The updated user.
   */
  public async getUpdatedAccount(): Promise<IUser> {
    const user = await this.getCurrentUser();
    const authorizedUser = UserService.isAuthenticated(user)
      ? await this.assertIsAuthorized(user, true)
      : null;

    if (authorizedUser) {
      await this.updateAccount(authorizedUser);

      return authorizedUser;
    }

    return user;
  }

  /**
   * Updates the user account adding the latest information from the remote account.
   * @param user - The authenticated user to update.
   * @returns The updated user account.
   */
  private async updateAccount(
    user: IAuthenticatedUser
  ): Promise<IAuthenticatedUser> {
    const account = await ServerAccountService.getUserAccount(
      user.account.id,
      user.tokens.accessToken
    );

    const userModel = new AuthenticatedUserModel({
      type: UserType.Authenticated,
      uuid: user.uuid,
      email: user.email,
      tokens: user.tokens,
      account: account as IAccount
    });

    await this.saveCurrentUser(userModel);

    return userModel.toDTO();
  }
}

export default new ServerUserService();
