/* eslint-disable @typescript-eslint/no-unsafe-return -- ES Lint is struggling was
 * mistaking some complex types as `any`. */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- ES Lint is struggling was
 * mistaking some complex types as `any`. */
/* eslint-disable max-classes-per-file -- The 2 abstract classes needed for the HTTP
 * Status factories are included in this file. */

import { Constructor, DTO, JSONObject, StringLiteral } from '@/type-utils';
import { StatusCodes } from 'http-status-codes';
import { errorFactory } from '../error-utils';
import { ServerCodeInClientError } from '../errors';

/**
 * `IHTTPStatus` is an interface for a custom `Error` representing HTTP statuses.
 * @see {@link HTTPStatus}
 */
interface IHTTPStatus<T extends StatusCodes, StatusName extends string> {
  /**
   * The HTTP status code.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
   */
  code: T;

  /**
   * The human-readable HTTP status in "PascalCase".
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
   * @see https://wiki.c2.com/?PascalCase
   */
  statusName: StringLiteral<StatusName>;

  /**
   * The message to shown when this HTTP status is not caught.
   */
  message: string;

  /**
   * The content type to include as the `Content-Type` header.
   * @see {@link ContentType}
   */
  contentType: ContentType;
}

/**
 * Describes valid content types.
 * The string values should be valid MIME types for the `Content-Type` header.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type
 */
export enum ContentType {
  PlainText = 'text/plain',
  JSON = 'application/json',
  HTML = 'text/html'
}

/**
 * `HTTPStatus` is a subclass of `Error`. It is used as a base class for throwing HTTP
 * statuses on the server.
 * @abstract - Because typescript can't seem to infer the constructors of the
 * derived classes otherwise, this is marked `abstract` here, rather than below.
 */
export class HTTPStatus<
    T extends StatusCodes = any,
    StatusName extends string = any
  >
  extends Error
  implements IHTTPStatus<T, StatusName>, Error
{
  private _body = '';

  /** @inheritDoc */
  public readonly code!: T;

  /** @inheritDoc */
  public readonly statusName!: StringLiteral<StatusName>;

  /** @inheritDoc */
  public readonly message!: string;

  /** @inheritDoc */
  public readonly contentType: ContentType;

  /** @returns The current response body to use when this HTTP status is thrown or returned. */
  public get body(): string {
    return this._body;
  }

  /**
   * @param body - The JSON-like object or a string to use as the response body for
   * this status.
   * @param [options] - The `ErrorOptions` to include when we wish to establish the cause of
   * the HTTP status.
   * @inheritdoc
   */
  public constructor(
    body?: string | JSONObject | DTO<unknown> | ErrorOptions,
    options?: ErrorOptions
  ) {
    // This constructor doesn't use overloads because it seems VS Code won't be able
    // to determine the documentation on the `HTTP` object, if we do, for some reason.

    // Not using `EnvironmentService` here to avoid a possible circular dependency.
    // If we're in constructing this in the client, then this API is being misused.
    if (typeof window !== 'undefined') {
      throw new ServerCodeInClientError(
        'An attempt to instantiate a subclass of `HTTPStatus` occurred on the client.' +
          '`HTTPStatus` may only be thrown from the server.'
      );
    }

    // If the first arg was passed.
    if (body) {
      // Check if the first arg is an error options object, so we know what to pass
      // to `super`.
      if (
        typeof body === 'object' &&
        'cause' in body &&
        body.cause instanceof Error
      ) {
        options = body;
        body = undefined;
      }
    }

    super(undefined, options);

    // Continue to check to see if the first arg is a response body.
    if (body) {
      // Otherwise, check if it's string.
      if (typeof body === 'string') {
        // If it's a string, then store that as the response body.
        this._body = body;
        this.contentType = ContentType.HTML;
      }
      // Otherwise, it is an object.
      else {
        // Stringify the object and use that as the body.
        this._body = JSON.stringify(body);
        this.contentType = ContentType.JSON;
      }
    } else {
      this.contentType = ContentType.PlainText;
    }
  }

  /**
   * Accepts either a JSON-like object or string to send as the response body for
   * this HTTP status.
   * @param body - The JSON-like object or a string to use as the response body for
   * this status.
   * @returns This instance of `HTTPStatus`.
   */
  public withResponseBody(body: JSONObject | DTO<unknown> | string): this {
    this._body = typeof body === 'string' ? body : JSON.stringify(body);
    return this;
  }
}

/**
 * `HTTPRedirectStatus` is a subclass of `HTTPStatus`. It is used as a base class for
 * handling redirect statuses when returned or thrown on the server-side.
 * @abstract - Because typescript can't seem to infer the constructors of the
 * derived classes otherwise, this is marked `abstract` here, rather than below.
 */
export class HTTPRedirectStatus<
  T extends StatusCodes,
  StatusName extends string
> extends HTTPStatus<T, StatusName> {
  /** The URL or relative path to redirect to as string. */
  public readonly redirectTo: string;

  /**
   * @param redirectTo - The URL or relative path to redirect to as a `URL` or string.
   * @param [options] - The `ErrorOptions` to include when we wish to establish the cause of
   * the redirect.
   * @inheritdoc
   */
  public constructor(redirectTo: string | URL, options?: ErrorOptions) {
    super('', options);
    this.redirectTo =
      redirectTo instanceof URL ? redirectTo.toString() : redirectTo;
  }
}

/* eslint-disable @typescript-eslint/explicit-function-return-type -- Let
 * the return type be inferred. */
/**
 * Generates a Error subclasses for an HTTP status or error.
 * @param errorName - The name of the class to return.
 * @param code - The HTTP status code associated with this constructor.
 * @param statusName - The human-readable status string corresponding to the code.
 * @param defaultMessage - The default message to associate with this error subclass.
 * @param [httpStatusToExtendFrom] - Optionally, the HTTPStatus constructor to extend from.
 * @returns An `Error` subclass used to represent a HTTP Status.
 */
const httpErrorFactory = <
  T,
  Code extends StatusCodes,
  StatusName extends string,
  ExtendFrom = typeof HTTPStatus<Code, StatusName>
>(
  errorName: StringLiteral<T>,
  code: Code,
  statusName: StatusName,
  defaultMessage: string,
  httpStatusToExtendFrom?: ExtendFrom
) => {
  // If `httpStatusToExtendFrom` was not passed, get it to the default.
  if (!httpStatusToExtendFrom) {
    httpStatusToExtendFrom = HTTPStatus as any;
  }
  return errorFactory(
    errorName,
    httpStatusToExtendFrom as unknown as Constructor<Error>,
    {
      code,
      statusName,
      message: defaultMessage
    }
  )[errorName] as unknown as ExtendFrom;
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */

/**
 * Checks if the given value is `HTTPStatus`-like (contains a `body` property).
 * @param value - The value to check.
 * @returns `true` if the value is `HTTPStatus`-like, `false` otherwise.
 */
export function isHTTPStatusLike(value: unknown): value is HTTPStatus {
  return !!value && typeof value === 'object' && 'body' in value;
}

/**
 * Generates an HTTP error message given a HTTP status code, status string, and
 * whether this message represents an error range or status.
 * @param code - The HTTP status code.
 * @param status - The human-readable HTTP status name string.
 * @param type - A string specifying whether this status is in the error range.
 * @returns A formatted error message string.
 */
const httpErrorMessageGenerator = (
  code: number,
  status: string,
  type: string
): string =>
  `A ${code} "${status}" HTTP ${type.toLowerCase()} was thrown. This error should be caught automatically. If you're seeing this, something has gone wrong. Check that your route is wrapped in the \`middleware\` function.`;

export const HTTP = {
  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.3.
   *
   * The request has been received but not yet acted upon. It is non-committal,
   * meaning that there is no way in HTTP to later send an asynchronous response
   * indicating the outcome of processing the request. It is intended for cases where
   * another process or server handles the request, or for batch processing.
   */
  AcceptedStatus: httpErrorFactory(
    'AcceptedStatus',
    202,
    'ACCEPTED',
    httpErrorMessageGenerator(202, 'ACCEPTED', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.3.
   *
   * This error response means that the server, while working as a gateway to get a
   * response needed to handle the request, got an invalid response.
   */
  BadGatewayError: httpErrorFactory(
    'BadGatewayError',
    502,
    'BAD_GATEWAY',
    httpErrorMessageGenerator(502, 'BAD_GATEWAY', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.1.
   *
   * This response means that server could not understand the request due to invalid
   * syntax.
   */
  BadRequestError: httpErrorFactory(
    'BadRequestError',
    400,
    'BAD_REQUEST',
    httpErrorMessageGenerator(400, 'BAD_REQUEST', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.8.
   *
   * This response is sent when a request conflicts with the current state of the
   * server.
   */
  ConflictError: httpErrorFactory(
    'ConflictError',
    409,
    'CONFLICT',
    httpErrorMessageGenerator(409, 'CONFLICT', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.2.1.
   *
   * This interim response indicates that everything so far is OK and that the client
   * should continue with the request or ignore it if it is already finished.
   */
  ContinueStatus: httpErrorFactory(
    'ContinueStatus',
    100,
    'CONTINUE',
    httpErrorMessageGenerator(100, 'CONTINUE', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.2.
   *
   * The request has succeeded and a new resource has been created as a result of it.
   * This is typically the response sent after a PUT request.
   */
  CreatedStatus: httpErrorFactory(
    'CreatedStatus',
    201,
    'CREATED',
    httpErrorMessageGenerator(201, 'CREATED', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.14.
   *
   * This response code means the expectation indicated by the Expect request header
   * field can't be met by the server.
   */
  ExpectationFailedError: httpErrorFactory(
    'ExpectationFailedError',
    417,
    'EXPECTATION_FAILED',
    httpErrorMessageGenerator(417, 'EXPECTATION_FAILED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.5.
   *
   * The request failed due to failure of a previous request.
   */
  FailedDependencyError: httpErrorFactory(
    'FailedDependencyError',
    424,
    'FAILED_DEPENDENCY',
    httpErrorMessageGenerator(424, 'FAILED_DEPENDENCY', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.3.
   *
   * The client does not have access rights to the content, i.e. They are
   * unauthorized, so server is rejecting to give proper response. Unlike 401, the
   * client's identity is known to the server.
   */
  ForbiddenError: httpErrorFactory(
    'ForbiddenError',
    403,
    'FORBIDDEN',
    httpErrorMessageGenerator(403, 'FORBIDDEN', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.5.
   *
   * This error response is given when the server is acting as a gateway and cannot
   * get a response in time.
   */
  GatewayTimeoutError: httpErrorFactory(
    'GatewayTimeoutError',
    504,
    'GATEWAY_TIMEOUT',
    httpErrorMessageGenerator(504, 'GATEWAY_TIMEOUT', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.9.
   *
   * This response would be sent when the requested content has been permanently
   * deleted from server, with no forwarding address. Clients are expected to remove
   * their caches and links to the resource. The HTTP specification intends this
   * status code to be used for "limited-time, promotional services". APIs should not
   * feel compelled to indicate resources that have been deleted with this status
   * code.
   */
  GoneError: httpErrorFactory(
    'GoneError',
    410,
    'GONE',
    httpErrorMessageGenerator(410, 'GONE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.6.
   *
   * The HTTP version used in the request is not supported by the server.
   */
  HttpVersionNotSupportedError: httpErrorFactory(
    'HttpVersionNotSupportedError',
    505,
    'HTTP_VERSION_NOT_SUPPORTED',
    httpErrorMessageGenerator(505, 'HTTP_VERSION_NOT_SUPPORTED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2324#section-2.3.2.
   *
   * Any attempt to brew coffee with a teapot should result in the error code "418
   * I'm a teapot". The resulting entity body MAY be short and stout.
   */
  ImATeapotError: httpErrorFactory(
    'ImATeapotError',
    418,
    'IM_A_TEAPOT',
    httpErrorMessageGenerator(418, 'IM_A_TEAPOT', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.6.
   *
   * The 507 (Insufficient Storage) status code means the method could not be
   * performed on the resource because the server is unable to store the
   * representation needed to successfully complete the request. This condition is
   * considered to be temporary. If the request which received this status code was
   * the result of a user action, the request MUST NOT be repeated until it is
   * requested by a separate user action.
   */
  InsufficientSpaceOnResourceError: httpErrorFactory(
    'InsufficientSpaceOnResourceError',
    419,
    'INSUFFICIENT_SPACE_ON_RESOURCE',
    httpErrorMessageGenerator(419, 'INSUFFICIENT_SPACE_ON_RESOURCE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.6.
   *
   * The server has an internal configuration error: the chosen variant resource is
   * configured to engage in transparent content negotiation itself, and is therefore
   * not a proper end point in the negotiation process.
   */
  InsufficientStorageError: httpErrorFactory(
    'InsufficientStorageError',
    507,
    'INSUFFICIENT_STORAGE',
    httpErrorMessageGenerator(507, 'INSUFFICIENT_STORAGE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.1.
   *
   * The server encountered an unexpected condition that prevented it from fulfilling
   * the request.
   */
  InternalServerError: httpErrorFactory(
    'InternalServerError',
    500,
    'INTERNAL_SERVER_ERROR',
    httpErrorMessageGenerator(500, 'INTERNAL_SERVER_ERROR', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.10.
   *
   * The server rejected the request because the Content-Length header field is not
   * defined and the server requires it.
   */
  LengthRequiredError: httpErrorFactory(
    'LengthRequiredError',
    411,
    'LENGTH_REQUIRED',
    httpErrorMessageGenerator(411, 'LENGTH_REQUIRED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.4.
   *
   * The resource that is being accessed is locked.
   */
  LockedError: httpErrorFactory(
    'LockedError',
    423,
    'LOCKED',
    httpErrorMessageGenerator(423, 'LOCKED', 'Error')
  ),

  /**
   * @deprecated
   * Official Documentation @
   * https://tools.ietf.org/rfcdiff?difftype=--hwdiff&url2=draft-ietf-webdav-protocol-06.txt.
   *
   * A deprecated response used by the Spring Framework when a method has failed.
   */
  MethodFailureError: httpErrorFactory(
    'MethodFailureError',
    420,
    'METHOD_FAILURE',
    httpErrorMessageGenerator(420, 'METHOD_FAILURE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.5.
   *
   * The request method is known by the server but has been disabled and cannot be
   * used. For example, an API may forbid DELETE-ing a resource. The two mandatory
   * methods, GET and HEAD, must never be disabled and should not return this error
   * code.
   */
  MethodNotAllowedError: httpErrorFactory(
    'MethodNotAllowedError',
    405,
    'METHOD_NOT_ALLOWED',
    httpErrorMessageGenerator(405, 'METHOD_NOT_ALLOWED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.2.
   *
   * This response code means that URI of requested resource has been changed.
   * Probably, new URI would be given in the response.
   */
  MovedPermanentlyStatus: httpErrorFactory(
    'MovedPermanentlyStatus',
    301,
    'MOVED_PERMANENTLY',
    httpErrorMessageGenerator(301, 'MOVED_PERMANENTLY', 'Status'),
    HTTPRedirectStatus
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.3.
   *
   * This response code means that URI of requested resource has been changed
   * temporarily. New changes in the URI might be made in the future. Therefore, this
   * same URI should be used by the client in future requests.
   */
  MovedTemporarilyStatus: httpErrorFactory(
    'MovedTemporarilyStatus',
    302,
    'MOVED_TEMPORARILY',
    httpErrorMessageGenerator(302, 'MOVED_TEMPORARILY', 'Status'),
    HTTPRedirectStatus
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.2.
   *
   * A Multi-Status response conveys information about multiple resources in
   * situations where multiple status codes might be appropriate.
   */
  MultiStatusStatus: httpErrorFactory(
    'MultiStatusStatus',
    207,
    'MULTI_STATUS',
    httpErrorMessageGenerator(207, 'MULTI_STATUS', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.1.
   *
   * The request has more than one possible responses. User-agent or user should
   * choose one of them. There is no standardized way to choose one of the responses.
   */
  MultipleChoicesStatus: httpErrorFactory(
    'MultipleChoicesStatus',
    300,
    'MULTIPLE_CHOICES',
    httpErrorMessageGenerator(300, 'MULTIPLE_CHOICES', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc6585#section-6.
   *
   * The 511 status code indicates that the client needs to authenticate to gain
   * network access.
   */
  NetworkAuthenticationRequiredError: httpErrorFactory(
    'NetworkAuthenticationRequiredError',
    511,
    'NETWORK_AUTHENTICATION_REQUIRED',
    httpErrorMessageGenerator(511, 'NETWORK_AUTHENTICATION_REQUIRED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.5.
   *
   * There is no content to send for this request, but the headers may be useful. The
   * user-agent may update its cached headers for this resource with the new ones.
   */
  NoContentStatus: httpErrorFactory(
    'NoContentStatus',
    204,
    'NO_CONTENT',
    httpErrorMessageGenerator(204, 'NO_CONTENT', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.4.
   *
   * This response code means returned meta-information set is not exact set as
   * available from the origin server, but collected from a local or a third party
   * copy. Except this condition, 200 OK response should be preferred instead of this
   * response.
   */
  NonAuthoritativeInformationStatus: httpErrorFactory(
    'NonAuthoritativeInformationStatus',
    203,
    'NON_AUTHORITATIVE_INFORMATION',
    httpErrorMessageGenerator(203, 'NON_AUTHORITATIVE_INFORMATION', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.6.
   *
   * This response is sent when the web server, after performing server-driven
   * content negotiation, doesn't find any content following the criteria given by
   * the user agent.
   */
  NotAcceptableError: httpErrorFactory(
    'NotAcceptableError',
    406,
    'NOT_ACCEPTABLE',
    httpErrorMessageGenerator(406, 'NOT_ACCEPTABLE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.4.
   *
   * The server can not find requested resource. In the browser, this means the URL
   * is not recognized. In an API, this can also mean that the endpoint is valid but
   * the resource itself does not exist. Servers may also send this response instead
   * of 403 to hide the existence of a resource from an unauthorized client. This
   * response code is probably the most famous one due to its frequent occurrence on
   * the web.
   */
  NotFoundError: httpErrorFactory(
    'NotFoundError',
    404,
    'NOT_FOUND',
    httpErrorMessageGenerator(404, 'NOT_FOUND', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.2.
   *
   * The request method is not supported by the server and cannot be handled. The
   * only methods that servers are required to support (and therefore that must not
   * return this code) are GET and HEAD.
   */
  NotImplementedError: httpErrorFactory(
    'NotImplementedError',
    501,
    'NOT_IMPLEMENTED',
    httpErrorMessageGenerator(501, 'NOT_IMPLEMENTED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7232#section-4.1.
   *
   * This is used for caching purposes. It is telling to client that response has not
   * been modified. So, client can continue to use same cached version of response.
   */
  NotModifiedStatus: httpErrorFactory(
    'NotModifiedStatus',
    304,
    'NOT_MODIFIED',
    httpErrorMessageGenerator(304, 'NOT_MODIFIED', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.1.
   *
   * The request has succeeded. The meaning of a success varies depending on the HTTP
   * method: GET: The resource has been fetched and is transmitted in the message
   * body. HEAD: The entity headers are in the message body. POST: The resource
   * describing the result of the action is transmitted in the message body. TRACE:
   * The message body contains the request message as received by the server.
   */
  OkStatus: httpErrorFactory(
    'OkStatus',
    200,
    'OK',
    httpErrorMessageGenerator(200, 'OK', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7233#section-4.1.
   *
   * This response code is used because of range header sent by the client to
   * separate download into multiple streams.
   */
  PartialContentStatus: httpErrorFactory(
    'PartialContentStatus',
    206,
    'PARTIAL_CONTENT',
    httpErrorMessageGenerator(206, 'PARTIAL_CONTENT', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.2.
   *
   * This response code is reserved for future use. Initial aim for creating this
   * code was using it for digital payment systems however this is not used
   * currently.
   */
  PaymentRequiredError: httpErrorFactory(
    'PaymentRequiredError',
    402,
    'PAYMENT_REQUIRED',
    httpErrorMessageGenerator(402, 'PAYMENT_REQUIRED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7538#section-3.
   *
   * This means that the resource is now permanently located at another URI,
   * specified by the Location: HTTP Response header. This has the same semantics as
   * the 301 Moved Permanently HTTP response code, with the exception that the user
   * agent must not change the HTTP method used: if a POST was used in the first
   * request, a POST must be used in the second request.
   */
  PermanentRedirectStatus: httpErrorFactory(
    'PermanentRedirectStatus',
    308,
    'PERMANENT_REDIRECT',
    httpErrorMessageGenerator(308, 'PERMANENT_REDIRECT', 'Status'),
    HTTPRedirectStatus
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7232#section-4.2.
   *
   * The client has indicated preconditions in its headers which the server does not
   * meet.
   */
  PreconditionFailedError: httpErrorFactory(
    'PreconditionFailedError',
    412,
    'PRECONDITION_FAILED',
    httpErrorMessageGenerator(412, 'PRECONDITION_FAILED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc6585#section-3.
   *
   * The origin server requires the request to be conditional. Intended to prevent
   * the 'lost update' problem, where a client GETs a resource's state, modifies it,
   * and PUTs it back to the server, when meanwhile a third party has modified the
   * state on the server, leading to a conflict.
   */
  PreconditionRequiredError: httpErrorFactory(
    'PreconditionRequiredError',
    428,
    'PRECONDITION_REQUIRED',
    httpErrorMessageGenerator(428, 'PRECONDITION_REQUIRED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.1.
   *
   * This code indicates that the server has received and is processing the request,
   * but no response is available yet.
   */
  ProcessingStatus: httpErrorFactory(
    'ProcessingStatus',
    102,
    'PROCESSING',
    httpErrorMessageGenerator(102, 'PROCESSING', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7235#section-3.2.
   *
   * This is similar to 401 but authentication is needed to be done by a proxy.
   */
  ProxyAuthenticationRequiredError: httpErrorFactory(
    'ProxyAuthenticationRequiredError',
    407,
    'PROXY_AUTHENTICATION_REQUIRED',
    httpErrorMessageGenerator(407, 'PROXY_AUTHENTICATION_REQUIRED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc6585#section-5.
   *
   * The server is unwilling to process the request because its header fields are too
   * large. The request MAY be resubmitted after reducing the size of the request
   * header fields.
   */
  RequestHeaderFieldsTooLargeError: httpErrorFactory(
    'RequestHeaderFieldsTooLargeError',
    431,
    'REQUEST_HEADER_FIELDS_TOO_LARGE',
    httpErrorMessageGenerator(431, 'REQUEST_HEADER_FIELDS_TOO_LARGE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.7.
   *
   * This response is sent on an idle connection by some servers, even without any
   * previous request by the client. It means that the server would like to shut down
   * this unused connection. This response is used much more since some browsers,
   * like Chrome, Firefox 27+, or IE9, use HTTP pre-connection mechanisms to speed up
   * surfing. Also note that some servers merely shut down the connection without
   * sending this message.
   */
  RequestTimeoutError: httpErrorFactory(
    'RequestTimeoutError',
    408,
    'REQUEST_TIMEOUT',
    httpErrorMessageGenerator(408, 'REQUEST_TIMEOUT', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.11.
   *
   * Request entity is larger than limits defined by server; the server might close
   * the connection or return an Retry-After header field.
   */
  RequestTooLongError: httpErrorFactory(
    'RequestTooLongError',
    413,
    'REQUEST_TOO_LONG',
    httpErrorMessageGenerator(413, 'REQUEST_TOO_LONG', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.12.
   *
   * The URI requested by the client is longer than the server is willing to
   * interpret.
   */
  RequestUriTooLongError: httpErrorFactory(
    'RequestUriTooLongError',
    414,
    'REQUEST_URI_TOO_LONG',
    httpErrorMessageGenerator(414, 'REQUEST_URI_TOO_LONG', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7233#section-4.4.
   *
   * The range specified by the Range header field in the request can't be fulfilled;
   * it's possible that the range is outside the size of the target URI's data.
   */
  RequestedRangeNotSatisfiableError: httpErrorFactory(
    'RequestedRangeNotSatisfiableError',
    416,
    'REQUESTED_RANGE_NOT_SATISFIABLE',
    httpErrorMessageGenerator(416, 'REQUESTED_RANGE_NOT_SATISFIABLE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.3.6.
   *
   * This response code is sent after accomplishing request to tell user agent reset
   * document view which sent this request.
   */
  ResetContentStatus: httpErrorFactory(
    'ResetContentStatus',
    205,
    'RESET_CONTENT',
    httpErrorMessageGenerator(205, 'RESET_CONTENT', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.4.
   *
   * Server sent this response to directing client to get requested resource to
   * another URI with an GET request.
   */
  SeeOtherStatus: httpErrorFactory(
    'SeeOtherStatus',
    303,
    'SEE_OTHER',
    httpErrorMessageGenerator(303, 'SEE_OTHER', 'Status'),
    HTTPRedirectStatus
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.6.4.
   *
   * The server is not ready to handle the request. Common causes are a server that
   * is down for maintenance or that is overloaded. Note that together with this
   * response, a user-friendly page explaining the problem should be sent. This
   * responses should be used for temporary conditions and the Retry-After: HTTP
   * header should, if possible, contain the estimated time before the recovery of
   * the service. The webmaster must also take care about the caching-related headers
   * that are sent along with this response, as these temporary condition responses
   * should usually not be cached.
   */
  ServiceUnavailableError: httpErrorFactory(
    'ServiceUnavailableError',
    503,
    'SERVICE_UNAVAILABLE',
    httpErrorMessageGenerator(503, 'SERVICE_UNAVAILABLE', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.2.2.
   *
   * This code is sent in response to an Upgrade request header by the client, and
   * indicates the protocol the server is switching too.
   */
  SwitchingProtocolsStatus: httpErrorFactory(
    'SwitchingProtocolsStatus',
    101,
    'SWITCHING_PROTOCOLS',
    httpErrorMessageGenerator(101, 'SWITCHING_PROTOCOLS', 'Status')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.7.
   *
   * Server sent this response to directing client to get requested resource to
   * another URI with same method that used prior request. This has the same semantic
   * than the 302 Found HTTP response code, with the exception that the user agent
   * must not change the HTTP method used: if a POST was used in the first request, a
   * POST must be used in the second request.
   */
  TemporaryRedirectStatus: httpErrorFactory(
    'TemporaryRedirectStatus',
    307,
    'TEMPORARY_REDIRECT',
    httpErrorMessageGenerator(307, 'TEMPORARY_REDIRECT', 'Status'),
    HTTPRedirectStatus
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc6585#section-4.
   *
   * The user has sent too many requests in a given amount of time ("rate limiting").
   */
  TooManyRequestsError: httpErrorFactory(
    'TooManyRequestsError',
    429,
    'TOO_MANY_REQUESTS',
    httpErrorMessageGenerator(429, 'TOO_MANY_REQUESTS', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7235#section-3.1.
   *
   * Although the HTTP standard specifies "unauthorized", semantically this response
   * means "unauthenticated". That is, the client must authenticate itself to get the
   * requested response.
   */
  UnauthorizedError: httpErrorFactory(
    'UnauthorizedError',
    401,
    'UNAUTHORIZED',
    httpErrorMessageGenerator(401, 'UNAUTHORIZED', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7725.
   *
   * The user-agent requested a resource that cannot legally be provided, such as a
   * web page censored by a government.
   */
  UnavailableForLegalReasonsError: httpErrorFactory(
    'UnavailableForLegalReasonsError',
    451,
    'UNAVAILABLE_FOR_LEGAL_REASONS',
    httpErrorMessageGenerator(451, 'UNAVAILABLE_FOR_LEGAL_REASONS', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc2518#section-10.3.
   *
   * The request was well-formed but was unable to be followed due to semantic
   * errors.
   */
  UnprocessableEntityError: httpErrorFactory(
    'UnprocessableEntityError',
    422,
    'UNPROCESSABLE_ENTITY',
    httpErrorMessageGenerator(422, 'UNPROCESSABLE_ENTITY', 'Error')
  ),

  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.13.
   *
   * The media format of the requested data is not supported by the server, so the
   * server is rejecting the request.
   */
  UnsupportedMediaTypeError: httpErrorFactory(
    'UnsupportedMediaTypeError',
    415,
    'UNSUPPORTED_MEDIA_TYPE',
    httpErrorMessageGenerator(415, 'UNSUPPORTED_MEDIA_TYPE', 'Error')
  ),

  /**
   * @deprecated
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.4.6.
   *
   * Was defined in a previous version of the HTTP specification to indicate that a
   * requested response must be accessed by a proxy. It has been deprecated due to
   * security concerns regarding in-band configuration of a proxy.
   */
  UseProxyStatus: httpErrorFactory(
    'UseProxyStatus',
    305,
    'USE_PROXY',
    httpErrorMessageGenerator(305, 'USE_PROXY', 'Status')
  ),

  /**
   * Official Documentation @
   * https://datatracker.ietf.org/doc/html/rfc7540#section-9.1.2.
   *
   * Defined in the specification of HTTP/2 to indicate that a server is not able to
   * produce a response for the combination of scheme and authority that are included
   * in the request URI.
   */
  MisdirectedRequestError: httpErrorFactory(
    'MisdirectedRequestError',
    421,
    'MISDIRECTED_REQUEST',
    httpErrorMessageGenerator(421, 'MISDIRECTED_REQUEST', 'Error')
  )
};
