import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { UnableToModifyResourceError } from '@/utils/errors';

/** Error to use when a user account could not be updated. */
export const { UnableToUpdateAccountError } = errorFactory(
  'UnableToUpdateAccountError',
  UnableToModifyResourceError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);
/** Error to use when a resource could not be created. */
export type UnableToUpdateAccountError = InstanceTypeOf<
  typeof UnableToUpdateAccountError
>;
