import { errorFactory } from '@/utils/error-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { UnableToModifyResourceError } from '@/utils/errors';

/** Error to use when an account could not be deleted. */
export const { UnableToDeleteAccountError } = errorFactory(
  'UnableToDeleteAccountError',
  UnableToModifyResourceError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);
