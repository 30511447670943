import { performance } from 'universal-perf-hooks';
import Service from '../../Service';
import { Nullable } from '@/type-utils';
import LogTree from 'console-log-tree';
import Measurable from './Measurable';
import LoggerService from '../LoggerService';

/** Represents a tree-like object structure. */
interface ITree {
  /** The label for this node in the tree. */
  name: string;

  /** Any nested items for this node. */
  children?: Array<ITree>;
}

const PerformanceDiagnosticsServices = new Map<
  string,
  Map<Nullable<string>, PerformanceDiagnosticsService>
>();

/** `PerformanceDiagnosticsService` is used to perform performance-measuring operations. */
class PerformanceDiagnosticsService extends Service {
  private subServices: Map<
    string,
    Map<Nullable<string>, PerformanceDiagnosticsService>
  > = new Map();

  private measurements: Array<Measurable> = [];
  private entries: Array<Measurable | PerformanceDiagnosticsService> = [];

  /**
   * @param name - The name of the service.
   * @param [context] - A unique identifier of any string type that provides context for the
   * measurements.
   * @inheritdoc
   */
  private constructor(
    public readonly name: string,
    public readonly context: Nullable<string>
  ) {
    super();
  }

  /**
   * Produces a service for the given `serviceName` and, optionally, `context`.
   * @param serviceName - The name of the service.
   * @param [context] - A unique identifier of any string type that provides context for the
   * measurements.
   * @returns Either a new or existing instance of the service for the given `serviceName`
   * and optional `context`.
   */
  public static get(
    serviceName: string,
    context: Nullable<string> = null
  ): PerformanceDiagnosticsService {
    if (PerformanceDiagnosticsServices.has(serviceName)) {
      const serviceMap = PerformanceDiagnosticsServices.get(serviceName);
      if (serviceMap?.has(context)) {
        const service = serviceMap.get(context);
        if (service) return service;
      }

      const pds = new PerformanceDiagnosticsService(serviceName, context);
      serviceMap?.set(serviceName, pds);
    }

    const pds = new PerformanceDiagnosticsService(serviceName, context);
    PerformanceDiagnosticsServices.set(serviceName, new Map([[context, pds]]));
    return pds;
  }

  /**
   * Produces a sub-service for the given `subServiceName` and, optionally, `context`.
   * @param subServiceName - The name of the sub-service nested within this service.
   * @param [context] - A unique identifier of any string type that provides context for the
   * measurements.
   * @returns Either a new or existing instance of the service for the given `serviceName`
   * and optional `context`.
   */
  public get(
    subServiceName: string,
    context: Nullable<string> = null
  ): PerformanceDiagnosticsService {
    if (this.subServices.has(subServiceName)) {
      const serviceMap = this.subServices.get(subServiceName);
      if (serviceMap?.has(context)) {
        const subService = serviceMap.get(context);
        if (subService) return subService;
      }

      const pds = new PerformanceDiagnosticsService(subServiceName, context);
      serviceMap?.set(subServiceName, pds);
      this.entries.push(pds);
      return pds;
    }

    const pds = new PerformanceDiagnosticsService(subServiceName, context);
    this.subServices.set(subServiceName, new Map([[context, pds]]));
    this.entries.push(pds);
    return pds;
  }

  /** @returns The time stamp at which the earliest measurement to started. */
  public get startTime(): number {
    let earliestStartTime = performance.now();

    for (const entry of this.entries) {
      const entryStartTime = entry.startTime;
      if (entryStartTime && entryStartTime < earliestStartTime) {
        earliestStartTime = entryStartTime;
      }
    }

    return earliestStartTime;
  }

  /** @returns The time stamp at which the last measurement to end ended. */
  public get completeTime(): number {
    let longestCompleteTime = 0;

    for (const entry of this.entries) {
      const entryCompleteTime = entry.completeTime;
      if (entryCompleteTime && entryCompleteTime > longestCompleteTime) {
        longestCompleteTime = entryCompleteTime;
      }
    }

    return longestCompleteTime > 0 ? longestCompleteTime : performance.now();
  }

  /** @returns The total amount of time for all entries within this service for this context in ms. */
  public get total(): number {
    // If there are no entires to measure, don't bother calculating.
    if (this.entries.length === 0) {
      return 0;
    }
    return this.completeTime - this.startTime;
  }

  /**
   * Adds a new measurement to this service instance.
   * @param whatsBeingMeasured - A string description of what is being measured.
   * @returns A `Measurable` object with which measurements can be started, restarted, or completed.
   */
  public addMeasurement(whatsBeingMeasured: string): Measurable {
    const measurable = new Measurable(whatsBeingMeasured);
    this.measurements.push(measurable);
    this.entries.push(measurable);
    return measurable;
  }

  /**
   * Logs and totals all timings related to this service and context to the logs.
   */
  public logTimings(): void {
    LoggerService.info(LogTree.parse(this.getTree()));
  }

  /**
   * Gets a tree-like object representing the timings related to this service and context.
   * @returns A tree-like object representing the timings related to this service and context.
   */
  private getTree(): ITree {
    const tree: Required<ITree> = {
      name: `"${this.name}": ${this.context ?? ''}`,
      children: []
    };

    for (const entry of this.entries) {
      if (entry instanceof Measurable) {
        tree.children.push({ name: entry.label });
      } else {
        tree.children.push(entry.getTree());
      }
    }

    tree.children.push({ name: `Total time: ${this.total.toFixed(3)} ms` });

    return tree;
  }
}

export default PerformanceDiagnosticsService;
