import type { InstanceTypeOf } from '@/type-utils';
import type { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { CookieNotFoundError } from '../../models/Cookie';

export const { SessionIDCookieNotFoundError } = errorFactory(
  'SessionIDCookieNotFoundError',
  CookieNotFoundError,
  {
    mapsToHTTPError: 'NotFoundError'
  } as IHTTPErrorMetaData
);

/** Error to use when the session cookie is not found. */
export type SessionIDCookieNotFoundError = InstanceTypeOf<
  typeof SessionIDCookieNotFoundError
>;
