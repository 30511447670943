import { ResourceNotFoundError } from '@/utils/errors';

import { DTO } from '@/type-utils';

import {
  ISession,
  SessionDataRecord,
  SessionModel
} from '../../../../models/Session';
import AWSService from '../AWSService';
import AWSSessionResponse from './AWSSessionResponse';
import IAWSSessionEvent from './IAWSSessionEvent';

/**
 * This service is for handling session data via AWS.
 */
export class AWSSessionService {
  private serviceID = 'session' as const;

  /**
   * Flattens an AWS Session Service response for easier processing.
   * @see {@link AWSSessionResponse} to learn more about how responses originally come.
   *
   * @param response - The AWS Session Service response as originally received.
   * @returns An array with all the session events included in the response, in {@link IAWSSessionEvent} form.
   */
  private flattenAWSResponse(
    response: AWSSessionResponse
  ): Array<IAWSSessionEvent> {
    const sessionEvents: Array<IAWSSessionEvent> = [];

    for (const sessionObject of response) {
      for (const sessionEventID of Object.keys(sessionObject)) {
        sessionEvents.push({
          ...sessionObject[sessionEventID],
          id: sessionEventID
        });
      }
    }

    return sessionEvents;
  }

  /**
   * Gets the latest session data from AWS based on its unique session ID.
   *
   * @param sessionID - The unique identified associated with the session
   * whose data we're attempting to acquire.
   * @returns The session data from the AWS session service.
   */
  public async getSession(sessionID: string): Promise<DTO<ISession>> {
    try {
      const response = await AWSService.call(this.serviceID, 'getSession', {
        sessionID
      });

      const awsData = response.data as unknown as AWSSessionResponse;

      // AWS Session Microservice returns a 204 status whenever a requested session doesn't exist.
      // So a 204 code or an empty data array means empty session.
      if (response.status === 204 && awsData.length === 0)
        throw new ResourceNotFoundError(
          `Session with ID ${sessionID} was not found or was empty.`
        );

      const flattenedData = this.flattenAWSResponse(awsData);

      // The current session state will always be the latest (first) session event in the response.
      const latestSessionEvent = flattenedData[0];

      const dataRecord: SessionDataRecord = {};
      const secureDataRecord: SessionDataRecord = {};

      for (const k of Object.keys(latestSessionEvent.data)) {
        dataRecord[k] = {
          value: latestSessionEvent.data[k],
          expiresOn: Infinity
        };
      }

      for (const k of Object.keys(latestSessionEvent.secure_data)) {
        secureDataRecord[k] = {
          value: latestSessionEvent.secure_data[k],
          expiresOn: Infinity
        };
      }

      const session = {
        id: sessionID,
        data: { data: dataRecord, isSecure: false },
        secureData: { data: secureDataRecord, isSecure: true }
      } as DTO<ISession>;

      return session;
    } catch (err) {
      // If the session is either empty or was not found...
      if (err instanceof ResourceNotFoundError) {
        // Return an empty session.
        return {
          id: sessionID,
          data: { data: {}, isSecure: false },
          secureData: { data: {}, isSecure: true }
        } as DTO<ISession>;
      }

      // Rethrow if not the case.
      throw err;
    }
  }

  /**
   * Pushes a session event to AWS.
   * @param session - The {@link SessionModel} with the data to push to AWS.
   */
  public async pushSessionEvent(session: SessionModel): Promise<void> {
    const { data, secureData, id } = session;

    const params = {
      body: {
        data: data.toKeyValuePairs(),
        secureData: secureData.toKeyValuePairs()
      },

      sessionID: id
    };

    await AWSService.call(this.serviceID, 'setSession', params);
  }
}

export default new AWSSessionService();
