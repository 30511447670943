import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { UnauthorizedError } = errorFactory('UnauthorizedError', {
  mapsToHTTPError: 'UnauthorizedError'
} as IHTTPErrorMetaData);

/** Generic Error to use when an operation fails due to missing authorization. */
export type UnauthorizedError = InstanceTypeOf<typeof UnauthorizedError>;
