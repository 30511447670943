import Service from '../../../../Service';
import { mocked } from '@/configs';
import MockService, { MockState, ServiceMock } from '../../../../isomorphic/MockService';
import { Constructor } from '@/type-utils';

import type { AWSAccountService } from './AWSAccountService';

const initialState = {};

/**  Mock for AWSAccountService. */
export default class AWSAccountServiceMock extends ServiceMock<AWSAccountService> {
  protected _state: MockState;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): AWSAccountService {
    return MockService.getMockOf(this.service) as unknown as AWSAccountService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<AWSAccountService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(this.service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    MockService.mockService(
      mocked.AWSAccountService,
      service,
      {
        changePassword: this.changePassword.bind(this)
      },
      {},
      this.state
    );
  }

  /** @inheritdoc */
  public async changePassword(
    newPassword: string,
    accountID?: string,
    accessToken?: string
  ): Promise<void> {
    // No-op in mock
  }
}
