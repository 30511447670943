import { errorFactory } from '@/utils/error-utils';

import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { RequestError } from '@/utils/errors';

/** Error to use when a forgot password request could not be made. */
export const { ForgotPasswordError } = errorFactory(
  'ForgotPasswordError',
  RequestError,
  {
    mapsToHTTPError: 'RequestError'
  }
);
