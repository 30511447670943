/** Enumerates the user authentication methods available for AWS Requests. */
export enum AWSAuthenticationMethod {
  /** No user authentication will be used for this request. */
  None,

  /**
   * The current user's ID will be included as the `user-id` header. Can be used
   * for both anonymous and authenticated users.
   */
  UserID,

  /**
   * The current user's Account ID will be included as the `account-id` header.
   * Can be only be used for authenticated users.
   */
  AccountID,

  /**
   * The current user's Account ID will be included as the `account-id` header,
   * along with a current access token as the `client-access-token` header. Can
   * be only be used for authenticated users.
   */
  AccountIDAndToken,

  /**
   * Similar to `AccountIDAndToken` but includes the refresh token as the `client-refresh-token` header.
   */
  AccountIDAndTokens
}
