import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { ForbiddenError } from '@/utils/errors';

export const { InvalidCognitoAccessTokenError } = errorFactory(
  'InvalidCognitoAccessTokenError',
  ForbiddenError
);

/**
 * Generic error to indicate that a Cognito Access Token in use failed
 * validation.
 */
export type InvalidCognitoAccessTokenError = InstanceTypeOf<
  typeof InvalidCognitoAccessTokenError
>;
