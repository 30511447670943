import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { JwtExpiredError } from 'aws-jwt-verify/error';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { CognitoAccessTokenPayload } from 'aws-jwt-verify/jwt-model';
import Service from '../../../Service';

import siteCached from '../../../utils/siteCached';
import { CognitoAccessTokenExpiredError } from './errors/CognitoAccessTokenExpiredError';
import { InvalidCognitoAccessTokenError } from './errors/InvalidCognitoAccessTokenError';

/** Server-only integration service for [Amazon Cognito](https://docs.aws.amazon.com/cognito/latest/developerguide/what-is-amazon-cognito.html). */
class ServerCognitoService extends Service {
  /**
   * Retrieves an Access Token Verifier configured for the current environment.
   * @returns The above.
   */
  @siteCached
  /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    -- It's better to infer the return type of this getter since it has constant
    typing determined internally by the CognitoJwtVerifier. */
  private get accessTokenVerifier() {
    const config = ConfigurationService.getConfig('cognito');

    const userPoolId = config.getSetting('userPoolID.endUserPool').value;
    const clientId = config.getSetting('clientID.endUserClient').value;

    return CognitoJwtVerifier.create({
      userPoolId,
      clientId,
      tokenUse: 'access'
    });
  }

  /**
   * Verifies a supplied access token.
   *
   * @param accessToken - The access token to verify.
   * @returns The payload of the access token if valid.
   *
   * @throws An {@link CognitoAccessTokenExpiredError} if the supplied token is
   * expired.
   * @throws  An {@link InvalidCognitoAccessTokenError} if the supplied token
   * is invalid for some other reason.
   */
  public async verifyAccessToken(
    accessToken: string
  ): Promise<CognitoAccessTokenPayload> {
    const verifier = this.accessTokenVerifier;

    try {
      const payload = await verifier.verify(accessToken);
      return payload;
    } catch (error) {
      if (error instanceof JwtExpiredError) {
        throw new CognitoAccessTokenExpiredError('Access token is expired.', {
          cause: error
        });
      }

      throw new InvalidCognitoAccessTokenError(
        'Access token failed validation for an unknown reason.',
        {
          cause: error
        }
      );
    }
  }

  /**
   * Verifies a supplied access token. Does not throw if the token is invalid.
   *
   * @param accessToken - The access token to verify.
   * @returns The payload of the access token if valid, or `null` otherwise.
   */
  public async safeVerifyAccessToken(
    accessToken: string
  ): Promise<CognitoAccessTokenPayload | null> {
    try {
      const payload = await this.verifyAccessToken(accessToken);
      return payload;
    } catch {
      return null;
    }
  }

  /**
   * Given an access token and a username, verify if said access token is valid
   * and belongs to the specified user.
   *
   * @param accessToken - The access token to verify.
   * @param username - The username of the user the token should belong to.
   *
   * @returns A `true` value if the access token is valid and belongs to the
   * specified user, and `false` otherwise.
   */
  public async isValidAccessTokenForUser(
    accessToken: string,
    username: string
  ): Promise<boolean> {
    const tokenPayload = await this.safeVerifyAccessToken(accessToken);

    if (!tokenPayload || tokenPayload.username !== username) {
      return false;
    }

    return true;
  }
}

// TODO: Mock this.
export default new ServerCognitoService();
