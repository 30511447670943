import { RouteType } from '@/services/models/Http';
import MiddlewareHandler from '@/next-utils/middleware/MiddlewareHandler';

// List, in order of execution, all global middleware here.
export default [
  // There are currently no global middlewares. Add any to this array as
  // necessary. Global middleware will run for all requests types that
  // match the request types specified in the middleware handler's instance.
  // For an example of a middleware, look in the '@/middlewares/router/'
  // directory. Global middleware files should be placed adjacent to this
  // file. Please delete this comment if you add any middlewares to this
  // array.
] as Array<MiddlewareHandler<Array<RouteType>>>;
