import i18nConfig from '@/../i18n.config.json';
import { I18NConfig as I18NConfigType } from 'next/dist/server/config-shared';
import { IncomingMessage } from 'http';
import { NextApiRequest } from 'next';
import { acceptLanguage } from 'next/dist/server/accept-header';

/**
 * Heuristically determines the locale in API requests based on the
 * `NEXT_LOCALE` cookie, `accept-language` header, or the default locale.
 * @param req - The Next API request object to interpret.
 * @returns The best possible locale string for the given request.
 * @throws If the locale cannot be located in the Next JS config file.
 */
export const getLocale = (req: NextApiRequest | IncomingMessage): string => {
  const i18n: I18NConfigType = i18nConfig as unknown as I18NConfigType;

  if (!i18n) {
    throw new Error(
      '`i18n` is `null` or `undefined`. Check that it exists in the ' +
        'Next JS config file.'
    );
  }

  const chosenLocale = i18n.locales.find(
    (locale) => locale === ('cookies' in req ? req?.cookies?.NEXT_LOCALE : null)
  );
  const detectedLocale =
    chosenLocale ??
    acceptLanguage(req.headers['accept-language'], i18n.locales);
  return detectedLocale || i18n.defaultLocale;
};
