import { errorFactory } from '@/utils/error-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { UnableToModifyResourceError } from '@/utils/errors';

/** Error to use when a user password could not be updated. */
export const { UnableToUpdatePasswordError } = errorFactory(
  'UnableToUpdatePasswordError',
  UnableToModifyResourceError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);
