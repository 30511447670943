import { Nullable } from '@/type-utils';
import Measure from './Measure';

/** Represents something which can be measured. */
export default class Measurable {
  private _measure: Nullable<Measure> = null;

  /**
   * @param whatsBeingMeasured - A string describing what is being measured.
   * @inheritdoc
   */
  public constructor(private readonly whatsBeingMeasured: string) {}

  /** @returns A string representing the name and state of what is being measured. */
  public get label(): string {
    if (!this._measure) {
      throw new Error(
        'The `.measure()` method must be called prior to getting `.label`.'
      );
    }
    const { total } = this._measure;
    return `"${this.whatsBeingMeasured}": ${
      total ? `took ${total.toFixed(3)} ms` : ' is still pending completion'
    }`;
  }

  /** @returns The numeric total of the measurement in milliseconds or `null` if not complete. */
  public get total(): Nullable<number> {
    if (!this._measure) {
      throw new Error(
        'The `.measure()` method must be called prior to getting `.total`.'
      );
    }
    return this._measure.total;
  }

  /** @returns The time stamp at which the measurement started. */
  public get startTime(): Nullable<number> {
    if (!this._measure) {
      throw new Error(
        'The `.measure()` method must be called prior to getting `.startTime`.'
      );
    }
    return this._measure.startTime;
  }

  /** @returns The time stamp at which the measurement completed. */
  public get completeTime(): Nullable<number> {
    if (!this._measure) {
      throw new Error(
        'The `.measure()` method must be called prior to getting `.completeTime`.'
      );
    }
    return this._measure.completeTime;
  }

  /**
   * Initiates the measurement. Returns a `Measure` object which can be used to stop or restart
   * the measurement. Calling this method implicitly calls the `.start()` method on the `Measure`
   * object.
   * @returns A `Measurement` object which can be used to end the measurement.
   */
  public measure(): Measure {
    let measure: Measure;

    if (this._measure) {
      measure = this._measure;
    } else {
      this._measure = measure = new Measure(this.whatsBeingMeasured);
    }

    measure.start();
    return measure;
  }
}
