import { JSONObject, Nullable } from '@/type-utils';
import { timeStampableToISOString } from '@/utils/time-utils';

import { AWSAuthenticationMethod } from '@/constructs/AWSAuthenticationMethod';
import { CardNetwork } from '@/constructs/CardNetwork';
import { Province } from '@/constructs/provinces/Province';
import { NotImplementedError } from '@/utils/errors';
import { removeNullish } from '@/utils/object-utils';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import Service from '../../../../Service';
import { Locale } from '../../../../isomorphic/I18NService';
import { IAccount, IProfile } from '../../../../models/Account';
import {
  IPaymentMethod,
  PaymentMethodType
} from '../../../../models/Account/PaymentMethod';
import { ICardPaymentMethod } from '../../../../models/Account/PaymentMethod/Cards';
import { IWallet } from '../../../../models/Account/Wallet';
import { IAddress, ISavedAddress } from '../../../../models/Address';

import AWSService from '../AWSService';

import AWSAccountServiceMock from './AWSAccountServiceMock';
import type IAWSAccount from './IAWSAccount';
import type IAWSAddress from './IAWSAddress';
import IAWSCreateAccount from './IAWSCreateAccount';
import IAWSCreateAccountResponse from './IAWSCreateAccountResponse';
import type { IAWSGetAccountOptions } from './IAWSGetAccountOptions';
import type IAWSProfile from './IAWSProfile';
import type IAWSSavedPayment from './IAWSSavedPayment';

/** Integration for the [AWS Account Microservice](https://app.swaggerhub.com/apis/DeckersOMS/DECA-AWSGW-API/v1-beta#/Account). */
export class AWSAccountService extends Service {
  private serviceID = 'account' as const;

  /**
   * Retrieves an account.
   *
   * @param accountID - The identifier of the user to fetch the account for.
   * @param accessToken - A valid access token for the user in question.
   * @param options - Additional options for the request.
   * @returns The user's account, in {@link IAWSAccount} form.
   */
  public async getAccount(
    accountID: string,
    accessToken: string,
    options?: Partial<IAWSGetAccountOptions>
  ): Promise<IAWSAccount> {
    const response = await AWSService.call(this.serviceID, 'getAccount', {
      params: {
        accountID,
        includeInactive: options?.includeInactiveCarts ?? false
      },

      authentication: AWSAuthenticationMethod.AccountIDAndToken,
      anonymousAuthenticationFallback: false,

      // Since the user is not actually logged in when they log in for the first
      // time, manually specify the account ID and access token to use.
      customAccountID: accountID,
      customAccessToken: accessToken
    });

    return response.data as unknown as IAWSAccount;
  }

  /**
   * Creates or updates an account.
   * @param account - The account to put, in {@link IAWSAccount} form.
   * @returns A promise that resolves on success.
   */
  public async putAccount(account: IAWSAccount): Promise<void> {
    await AWSService.call(this.serviceID, 'putAccount', {
      body: account as unknown as JSONObject
    });
  }

  /**
   * Send account creation request data.
   * @param account - The account to put, in {@link IAWSAccount} form.
   * @returns The Cognito account response data.
   */
  public async postAccount(
    account: IAWSCreateAccount
  ): Promise<IAWSCreateAccountResponse> {
    const response = await AWSService.call(this.serviceID, 'postAccount', {
      body: account as unknown as JSONObject
    });

    return response.data as unknown as IAWSCreateAccountResponse;
  }

  /**
   * Update a profile.
   *
   * @param profile - The new profile data.
   * @param [accountID] - The ID of the account this profile belongs to.
   * If unspecified, the ID of the current user's account will be used.
   * @param [accessToken] - The access token to authenticate the request with.
   * If unspecified, the service will attempt to get an access token from the current user.
   *
   * @returns A promise that resolves on success.
   */
  public async putProfile(
    profile: IAWSProfile,
    accountID?: string,
    accessToken?: string
  ): Promise<void> {
    await AWSService.call(this.serviceID, 'putProfile', {
      body: {
        profile: removeNullish(profile) as unknown as JSONObject
      },

      authentication: AWSAuthenticationMethod.AccountIDAndToken,
      anonymousAuthenticationFallback: false,

      customAccessToken: accessToken,
      customAccountID: accountID
    });
  }

  /**
   * Change password.
   *
   * @param previousPassword - The previous password.
   * @param newPassword - The new password.
   * @param [accountID] - The ID of the account this profile belongs to.
   * If unspecified, the ID of the current user's account will be used.
   * @param [accessToken] - The access token to authenticate the request with.
   * If unspecified, the service will attempt to get an access token from the current user.
   *
   * @returns A promise that resolves on success.
   */
  public async changePassword(
    previousPassword: string,
    newPassword: string,
    accountID: string,
    accessToken: string
  ): Promise<void> {
    await AWSService.call(this.serviceID, 'changePassword', {
      body: {
        previousPassword,
        newPassword
      },

      authentication: AWSAuthenticationMethod.AccountIDAndToken,
      anonymousAuthenticationFallback: false,

      customAccessToken: accessToken,
      customAccountID: accountID
    });
  }

  /**
   * Forgot password.
   *
   * Initiates a password reset flow and sends a verification code to the
   * user's email address.
   *
   * @param email - The user's email address.
   * @returns A promise that resolves on success.
   */
  public async forgotPassword(email: string): Promise<void> {
    // Retrieve the forgot password base URL from the environment configuration.
    const environmentConfig = ConfigurationService.getConfig('environment');
    // Get the base url from the environment config so that it matches AWS domain whitelisting.
    const forgotPasswordBaseUrl = environmentConfig.getSetting('baseURL').value;
    const url = new URL(`${forgotPasswordBaseUrl}/account/forgot-password`);
    const callbackUrl = url.toString();

    await AWSService.call(this.serviceID, 'forgotPassword', {
      params: {
        email,
        callbackUrl
      }
    });
  }

  /**
   * Transforms a first-party {@link IAccount} into an {@link IAWSAccount}.
   *
   * @param account - {@link IAccount} To transform.
   *
   * @throws A {@link} NotImplementedError.
   */
  public transformAccount(account: IAccount): IAWSAccount {
    throw new NotImplementedError(
      'Transforming first-party accounts to AWS Accounts is not yet allowed.'
    );
  }

  /**
   * Transforms a first-party {@link IProfile} into an {@link IAWSProfile}.
   *
   * @param profile - {@link IProfile} To transform.
   * @returns An equivalent {@link IAWSProfile}.
   */
  public transformProfile(profile: IProfile): IAWSProfile {
    const { firstName, lastName, email, birthday, phoneNumber } = profile;

    return {
      firstName,
      lastName,
      email,

      birthday: birthday
        ? // Format: YYYY-MM-DD
          timeStampableToISOString(birthday).split('T')[0]
        : null,

      phoneNumber
    };
  }

  /**
   * Transforms a first-party {@link IWallet} into a collection of {@link IAWSSavedPayment} objects,
   * indexed by ID (just the way the API likes them).
   *
   * @param wallet - {@link IWallet} To transform.
   * @returns A map of {@link IAWSSavedPayment} objects.
   */
  private transformWallet(wallet: IWallet): Record<string, IAWSSavedPayment> {
    const { paymentMethods } = wallet;
    const awsWallet: Record<string, IAWSSavedPayment> = {};

    for (const paymentMethod of paymentMethods) {
      // TODO: Support more than cards
      awsWallet[paymentMethod.uuid] = this.transformPaymentMethod(
        paymentMethod as ICardPaymentMethod
      );
    }

    return awsWallet;
  }

  /**
   * Given a {@link CardNetwork} value, gets the equivalent string to be used
   * as the card `type` in an {@link IAWSSavedPayment}.
   *
   * @param cardNetwork - Card network to get the matching string for.
   * @returns A matching card type string.
   *
   * @throws An {@link NotImplementedError} if an unsupported network is specified.
   */
  private getEquivalentAWSCardType(cardNetwork: CardNetwork): string {
    switch (cardNetwork) {
      case CardNetwork.Visa: {
        return 'visa';
      }

      default: {
        throw new NotImplementedError(
          `Unsupported credit card network ${cardNetwork}`
        );
      }
    }
  }

  /**
   * Transforms a first-party {@link IPaymentMethod} into an {@link IAWSSavedPayment}.
   *
   * @param paymentMethod - {@link IPaymentMethod} To transform.
   * @returns An equivalent {@link IAWSSavedPayment}.
   */
  private transformPaymentMethod(
    paymentMethod: ICardPaymentMethod
  ): IAWSSavedPayment {
    const { type, maskedNumber, token, expiration, network, billingAddress } =
      paymentMethod;

    return {
      cardType: this.getEquivalentAWSCardType(network),
      token,
      maskedNumber,
      expiration: timeStampableToISOString(expiration),
      billingAddress: this.transformAddress(billingAddress)
    };
  }

  /**
   * Transforms a first-party {@link ISavedAddress} into a collection of {@link IAWSAddress} objects,
   * indexed by ID (just the way the API likes them).
   *
   * @param addresses - {@link ISavedAddress} To transform.
   * @returns A map of {@link IAWSAddress} objects.
   */
  private transformSavedAddresses(
    addresses: Array<ISavedAddress>
  ): Record<string, IAWSAddress> {
    const addressbook: Record<string, IAWSAddress> = {};

    for (const address of addresses) {
      addressbook[address.uuid] = this.transformAddress(address);
    }

    return addressbook;
  }

  /**
   * Transforms a first-party {@link IAddress} into an {@link IAWSAddress}.
   *
   * @param address - {@link IAddress} To transform.
   * @returns An equivalent {@link IAWSAddress}.
   */
  private transformAddress(address: IAddress): IAWSAddress {
    const {
      addressLine1,
      addressLine2,
      city,
      stateProvince,
      country,
      zipPostalCode,
      firstName,
      lastName
    } = address;

    return {
      line1: addressLine1,
      line2: addressLine2,
      firstName,
      lastName,
      postalCode: zipPostalCode,
      city,
      state: stateProvince,
      country
    };
  }

  /**
   * Transforms an {@link IAWSAccount} into a first-party {@link IAccount}.
   *
   * @param awsAccount - {@link IAWSAccount} To transform.
   * @param accountID - An identifier to include as the {@link IAccount}'s `id` value.
   *
   * @returns An equivalent {@link IAccount}.
   */
  public transformAWSAccount(
    awsAccount: IAWSAccount,
    accountID: string
  ): IAccount {
    const {
      profile,
      wallet,
      addressbook,
      last_login,
      orders = [],
      carts
    } = awsAccount;

    return {
      id: accountID,
      profile: this.transformAWSProfile(profile, accountID),
      wallet: this.transformAWSWallet(wallet, accountID),
      savedAddresses: this.transformAWSAddressBook(addressbook, accountID),

      lastLoggedIn: last_login,

      orderHistory: orders.map(({ id, purchased }) => ({
        orderID: id,
        placeDate: purchased
      })),

      activeCartID: carts?.find((cart) => cart.isActive)?.id ?? null
    };
  }

  /**
   * Deletes personal information.
   *
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param accessToken - The access token to authenticate the request with.
   * If unspecified, the service will attempt to get an access token from the current user.
   * @param refreshToken - A valid refresh token to supply to the delete request.
   */
  public async deleteAccount(
    accountID: string,
    accessToken: string,
    refreshToken: string
  ): Promise<void> {
    await AWSService.call(this.serviceID, 'deleteAccount', {
      authentication: AWSAuthenticationMethod.AccountIDAndTokens,
      anonymousAuthenticationFallback: false,

      customAccessToken: accessToken,
      customAccountID: accountID,
      customRefreshToken: refreshToken
    });
  }

  /**
   * Reset user's password.
   *
   * The main difference between `forgotPasswordReset` and `changePassword` is that the user doesn't need to be
   * authenticated to change password. They only need to specify the confirmation code and a new password.
   * @param requestToken - A unique request token (directly related to the submitted email address) that gets generated by AWS and then appended
   * to a password reset url sent to the user's email. This authenticates the user and allows them to change their password.
   * @param newPassword - The new password.
   * @param callbackUrl - The URL to send user to after resetting their password - '/login' page.
   * @param confirmationCode - The confirmation code used for authentication.
   */
  public async forgotPasswordReset(
    requestToken: string,
    newPassword: string,
    confirmationCode: string,
    callbackUrl: string
  ): Promise<void> {
    await AWSService.call(this.serviceID, 'forgotPasswordReset', {
      body: {
        requestToken,
        newPassword,
        confirmationCode,
        callbackUrl
      }
    });
  }

  /**
   * Transforms an {@link IAWSProfile} into a first-party {@link IProfile}.
   *
   * @param awsProfile - {@link IAWSProfile} To transform.
   * @param accountID - An account identifier to include as the {@link IProfile}'s `accountID` value.
   *
   * @returns An equivalent {@link IProfile}.
   */
  private transformAWSProfile(
    awsProfile: IAWSProfile,
    accountID: string
  ): IProfile {
    const { firstName, lastName, email, phoneNumber, birthday } = awsProfile;

    return {
      accountID,

      firstName,
      lastName,
      email,
      phoneNumber,
      birthday: birthday ?? ''
    };
  }

  /**
   * Transforms a collection of {@link IAWSSavedPayment} objects (the way the API returns them) into
   * a first-party {@link IWallet}.
   *
   * @param awsWallet - The collection with the AWS saved payments.
   * @param accountID - An account identifier to include as the {@link IWallet}'s `accountID` value.
   *
   * @returns An equivalent {@link IWallet}.
   */
  private transformAWSWallet(
    awsWallet: Record<string, IAWSSavedPayment>,
    accountID: string
  ): IWallet {
    const paymentIDs = Object.keys(awsWallet);

    const paymentMethods: Array<IPaymentMethod> = paymentIDs.map(
      (paymentUUID) => {
        const { cardType, token, maskedNumber, expiration, billingAddress } =
          awsWallet[paymentUUID];

        return {
          accountID,
          uuid: paymentUUID,

          type: PaymentMethodType.CreditCard,

          cardType,
          token,
          maskedNumber,
          expiration,

          billingAddress: this.transformAWSAddress(billingAddress)
        };
      }
    );

    return {
      accountID,
      paymentMethods
    };
  }

  /**
   * Transforms an {@link IAWSAddress} into a first-party {@link IAddress}.
   *
   * @param awsAddress - {@link IAWSAddress} To transform.
   * @returns An equivalent {@link IAddress}.
   */
  private transformAWSAddress(awsAddress: IAWSAddress): IAddress {
    const {
      line1,
      line2,
      firstName,
      lastName,
      postalCode,
      city,
      state,
      country
    } = awsAddress;

    return {
      addressLine1: line1,
      addressLine2: line2 ?? undefined,
      firstName,
      lastName,
      zipPostalCode: postalCode,
      city,
      stateProvince: state as Province,
      country: country as Locale
    };
  }

  /**
   * Transforms an {@link IAWSSavedAddress} into a first-party {@link ISavedAddress}.
   *
   * @param awsAddress - {@link IAWSSavedAddress} To transform.
   * @param addressID - The `uuid` to use for the new saved address.
   * @param accountID - An account identifier to include as the {@link ISavedAddress}'s `accountID` value.
   * @param [nickName] - The `nickname` to use for the new save address.
   *
   * @returns An equivalent {@link ISavedAddress}.
   */
  private transformAWSSavedAddress(
    awsAddress: IAWSAddress,
    addressID: string,
    accountID: string,
    nickName?: Nullable<string>
  ): ISavedAddress {
    return {
      ...this.transformAWSAddress(awsAddress),

      uuid: addressID,
      accountID,
      nickName
    };
  }

  /**
   * Transforms a collection of {@link IAWSAddress} objects (the way the API returns them) into
   * an array of first-party {@link ISavedAddress} objects.
   *
   * @param awsAddressBook - The AWS address book.
   * @param accountID - An account identifier to include as the `accountID` value in the {@link ISavedAddress} objects.
   *
   * @returns An array with equivalent {@link ISavedAddress} objects.
   */
  private transformAWSAddressBook(
    awsAddressBook: Record<string, IAWSAddress>,
    accountID: string
  ): Array<ISavedAddress> {
    const addressIDs = Object.keys(awsAddressBook);

    return addressIDs.map((addressID) =>
      // TODO: Add nicknames here.
      this.transformAWSSavedAddress(
        awsAddressBook[addressID],
        addressID,
        accountID
      )
    );
  }
}

export default AWSAccountService.withMock(
  new AWSAccountServiceMock(AWSAccountService)
) as unknown as AWSAccountService;
