import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

import { UserAccountAlreadyExistsError } from '@/services/isomorphic/UserService';
import type { ISignUpCredentials } from '@/services/models/User/AuthenticatedUser';
import { DTO } from '@/type-utils';
import { NotImplementedError, RequestConflictError } from '@/utils/errors';
import Service from '../../Service';

import {
  AccountModel,
  IAccount,
  IProfile,
  UnableToAccessAccountError,
  UnableToUpdateAccountError
} from '../../models/Account';

import AWSAccountService, {
  IAWSCreateAccount
} from '../integrations/AWS/AWSAccountService';
import { ForgotPasswordError } from './errors/ForgotPasswordError';
import { UnableToDeleteAccountError } from './errors/UnableToDeleteAccountError';
import { UnableToUpdatePasswordError } from './errors/UnableToUpdatePasswordError';

/** */
export class ServerAccountService extends Service {
  /**
   * Updates a provided customer account with the data existing in the provided
   * {@link IAccount} object.
   *
   * @param account - An account with updated information.
   * @throws An {@link UnableToUpdateUserAccountError} when the account could not be
   * updated. See the `cause` property for more details about what specifically went wrong.
   */
  public async updateAccount(account: IAccount): Promise<void> {
    throw new NotImplementedError(
      'Updating whole accounts at once has not been implemented yet.'
    );
  }

  /**
   * Updates a provided customer profile.
   *
   * @param profile - An {@link IProfile} object with the new data.
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param accessToken - A valid access token to authenticate the request.
   *
   * @throws An {@link UnableToUpdateUserAccountError} when the account could not be
   * updated. See the `cause` property for more details about what specifically went wrong.
   */
  public async updateProfile(
    profile: IProfile,
    accessToken: string
  ): Promise<void> {
    const { accountID } = profile;

    try {
      const transformedProfile = AWSAccountService.transformProfile(profile);

      await AWSAccountService.putProfile(
        transformedProfile,
        accountID,
        accessToken
      );
    } catch (e) {
      throw new UnableToUpdateAccountError(
        'The user profile could not be updated.',
        {
          cause: e as Error
        }
      );
    }
  }

  /**
   * Creates a customer account with the data existing in the provided
   * {@link IAWSCreateAccount} object.
   *
   * @param credentials - The account signup credentials.
   * @param accountID - The account ID.
   * @throws An {@link Error} when the account could not be
   * created. See the `cause` property for more details about what specifically went wrong.
   * @returns The Account from the AWS Service.
   */
  public async createAccount(
    credentials: ISignUpCredentials
  ): Promise<IAccount> {
    try {
      // Sign the user up...
      const accountInfo = {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        email: credentials.email,
        phoneNumber: credentials.phoneNumber?.e164Formatted ?? '',
        password: credentials.password
      };
      const response = await AWSAccountService.postAccount(accountInfo);

      return AWSAccountService.transformAWSAccount(response, response.id);
    } catch (e) {
      if (e instanceof RequestConflictError) {
        throw new UserAccountAlreadyExistsError(
          'The username is already taken.',
          {
            cause: e as Error
          }
        );
      }

      throw e;
    }
  }

  /**
   * Changes user password.
   *
   * @param newPassword - The new password.
   * @param previousPassword - The previous password.
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param accessToken - A valid access token to authenticate the request.
   *
   * @throws An {@link UnableToUpdatePasswordError} when the password could not be
   * updated. See the `cause` property for more details about what specifically went wrong.
   */
  public async changePassword(
    newPassword: string,
    previousPassword: string,
    accountID: string,
    accessToken: string
  ): Promise<void> {
    try {
      await AWSAccountService.changePassword(
        newPassword,
        previousPassword,
        accountID,
        accessToken
      );
    } catch (e) {
      throw new UnableToUpdatePasswordError(
        'The user password could not be updated.',
        {
          cause: e as Error
        }
      );
    }
  }

  /**
   * Forgot password.
   *
   * Initiates a password reset flow and sends a verification code to the
   * user's email address.
   *
   * @param email - The user's email address entered in the forgot password form.
   * @throws An {@link ForgotPasswordError} when the request to send forgot password verification
   * code could not be made. See the `cause` property for more details about what specifically went wrong.
   */
  public async forgotPassword(email: string): Promise<void> {
    try {
      await AWSAccountService.forgotPassword(email);
    } catch (e) {
      throw new ForgotPasswordError(
        'Cannot request a forgot password verification code.',
        {
          cause: e as Error
        }
      );
    }
  }

  /**
   * Retrieves a User's account.
   *
   * @param accountID - The user's identifier.
   * @param accessToken - A valid access token to authenticate the request.
   *
   * @returns The user's account as an {@link IAccount} DTO.
   */
  public async getUserAccount(
    accountID: string,
    accessToken: string
  ): Promise<DTO<IAccount>> {
    try {
      const awsAccount = await AWSAccountService.getAccount(
        accountID,
        accessToken
      );

      const accountDTO = AWSAccountService.transformAWSAccount(
        awsAccount,
        accountID
      );

      return AccountModel.toDTO(accountDTO);
    } catch (e) {
      throw new UnableToAccessAccountError(
        'The user account for the provided ID could not be accessed.',
        { cause: e as Error }
      );
    }
  }

  /**
   * Reset user's password.
   *
   * The main difference between `forgotPasswordReset` and `changePassword` is that the user doesn't need to be
   * authenticated to change password. They only need to specify the confirmation code and a new password.
   * @param requestToken - A unique request token (directly related to the submitted email address) that gets generated by AWS and then appended
   * to a password reset url sent to the user's email. This authenticates the user and allows them to change their password.
   * @param newPassword - The new password.
   * @param confirmationCode - The confirmation code used for authentication.
   *
   * * @throws An {@link UnableToUpdatePasswordError} when the password could not be
   * updated. See the `cause` property for more details about what specifically went wrong.
   */
  public async forgotPasswordReset(
    requestToken: string,
    newPassword: string,
    confirmationCode: string
  ): Promise<void> {
    const { origin } = EnvironmentService.url;
    const callbackUrl = `${origin}/login`;
    try {
      await AWSAccountService.forgotPasswordReset(
        requestToken,
        newPassword,
        confirmationCode,
        callbackUrl
      );
    } catch (e) {
      throw new UnableToUpdatePasswordError(
        'The user password could not be updated.',
        {
          cause: e as Error
        }
      );
    }
  }

  /**
   * Deletes personal information.
   *
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param accessToken - The access token to authenticate the request with.
   * If unspecified, the service will attempt to get an access token from the current user.
   * @param refreshToken - A valid refresh token to supply to the delete request.
   */
  public async deleteAccount(
    accountID: string,
    accessToken: string,
    refreshToken: string
  ): Promise<void> {
    try {
      await AWSAccountService.deleteAccount(
        accountID,
        accessToken,
        refreshToken
      );
    } catch (e) {
      throw new UnableToDeleteAccountError(
        'The account could not be deleted.',
        {
          cause: e as Error
        }
      );
    }
  }
}

// TODO: Mock this.
export default new ServerAccountService();
