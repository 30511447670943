import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ClientRequest, Agent as HTTPAgent } from 'node:http';
import { Agent as HTTPSAgent } from 'node:https';

import type awsServices from '@/configs/services/awsServices';

import type { ConfigSchema } from '@/configs';

import { JSONObject, JSONValue, Nullable } from '@/type-utils';
import { UnionTypeToMergedTyped } from '@/type-utils/Merge';

import {
  InvalidArgumentError,
  InvalidStateError,
  RequestConflictError,
  RequestError,
  ResourceNotFoundError
} from '@/utils/errors';

import { AWSAuthenticationMethod } from '@/constructs/AWSAuthenticationMethod';
import CurrentRequestService from '@/services/isomorphic/CurrentRequestService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { UnableToDetermineSessionIDError } from '@/services/isomorphic/SessionService';
import { UserNotAuthenticatedError } from '@/services/isomorphic/UserService';
import ServerUserService from '@/services/serverless/ServerUserService';
import siteCached from '@/services/utils/siteCached';
import { HTTPMethod } from '@/type-utils/HTTPMethod';
import { replaceStringVariables } from '@/utils/string-utils';
import { SessionIDCookieNotFoundError } from '../../../../isomorphic/SessionService/SessionIDCookieNotFoundError';

import ConfigurationService, {
  Config
} from '../../../../isomorphic/ConfigurationService';
import { EnvironmentService } from '../../../../isomorphic/EnvironmentService';
import I18NService from '../../../../isomorphic/I18NService';
import ServerSessionService from '../../../ServerSessionService';

/**
 * Formats the AWS URL from the required parameters.
 * @param apiGateway - The URL origin for the AWS API gateway.
 * @param serviceEndpoint - The endpoint of the target service.
 * @returns A URL as a string.
 */
const awsURLTemplate = (apiGateway: string, serviceEndpoint: string): string =>
  `${apiGateway}/${serviceEndpoint}`;

/**
 * Get the type structure for the AWSService configuration as defined in
 * `'@/configs/awsServices'`.
 */
type AWSServices =
  typeof awsServices extends ConfigSchema<infer T>
    ? T extends { default: { default: unknown } }
      ? T['default']['default']
      : never
    : never;

/** Gets the configured AWS services as a union of strings. */
export type ConfiguredService = keyof AWSServices;

/** Gets the service method configuration for a given configured service name `T`. */
type ServiceMethodMap<T extends ConfiguredService> = {
  [Key in keyof AWSServices[T]['methods']]: AWSServices[T]['methods'][Key];
};

/** Gets a string union of all possible service methods across all services. */
type AllServicePossibleMethods = keyof UnionTypeToMergedTyped<
  AWSServices[keyof AWSServices]
>['methods'];

/** Gets a string union of possible service methods of a given service name `T`. */
export type ServiceMethods<T extends ConfiguredService> =
  keyof ServiceMethodMap<T>;

/** Describes valid options for AWS service requests. */
export interface IAWSRequestOptions {
  /** Request body. */
  body?: Record<string, JSONValue<boolean>>;

  /** URL parameters. */
  params?: Record<string, JSONValue>;

  /** Endpoint parameters appear in the config within '{}'. */
  endpointParams?: Record<string, string>;

  /** Request headers. */
  headers?: Record<string, JSONValue>;

  /**
   * A custom Session ID to include in the request.
   * If unspecified, it will be grabbed automatically from the current session.
   */
  sessionID?: string;

  /**
   * Specifies the user authentication mechanism to use for the request.
   *
   * Mainly, if a user ID, account ID, and/or and an access token should be
   * included in the request headers.
   *
   * Default value: {@link AWSAuthenticationMethod.AccountID `AccountID`}.
   *
   * @see {@link AWSAuthenticationMethod} for all possible authentication
   * options.
   *
   * @see {@link IAWSRequestOptions.anonymousAuthenticationFallback} for
   * fallback behaviors.
   */
  authentication?: AWSAuthenticationMethod;

  /**
   * Specifies the fallback authentication mechanism to use if a user is not
   * logged in and the primary mechanism specified requires them to be logged in.
   *
   * These kinds of cases can only occur with `accountID` or
   * `accountIDAndToken`.
   *
   * **Possible values:**
   * - {@link AWSAuthenticationMethod.UserID `UserID`}: Default value. Fall back to including the current user's ID
   * as the `user-id` header.
   * - {@link AWSAuthenticationMethod.None `None`}: Do not include any authentication headers if the user is not
   * logged in.
   * - `false`: Falling back is not allowed, so throw if the user is not logged
   * in.
   */
  anonymousAuthenticationFallback?:
    | AWSAuthenticationMethod.UserID
    | AWSAuthenticationMethod.None
    | false;

  /**
   * A custom user ID to include in the request as the `user-id` header. Will
   * take precedence over the current user's ID if the
   * {@link AWSAuthenticationMethod.UserID `UserID`} {@link IAWSRequestOptions.anonymousAuthenticationFallback authentication mechanism}
   * is specified.
   */
  customUserID?: string;

  /**
   * A custom account ID to include in the request as the `account-id` header.
   * Will take precedence over the current user's account ID if either the
   * {@link AWSAuthenticationMethod.AccountID `AccountID`} or the {@link AWSAuthenticationMethod.AccountIDAndToken `AccountIDAndToken`} {@link IAWSRequestOptions.anonymousAuthenticationFallback authentication mechanism}
   * is specified.
   *
   * Useful when initially fetching a User's account, since the login process is
   * not yet complete but the account ID is not yet saved to session.
   */
  customAccountID?: string;

  /**
   * A custom access token to include in the request as the
   * `client-access-token` header. Will take precedence over the current user's
   * access token if the {@link AWSAuthenticationMethod.AccountIDAndToken `AccountIDAndToken`} {@link IAWSRequestOptions.anonymousAuthenticationFallback authentication mechanism}
   * is specified.
   *
   * Useful when initially fetching a User's account, since the login process is
   * not yet complete but the tokens are accessible by this point.
   */
  customAccessToken?: string;

  /**
   * A custom refresh token to include in the request as the
   * `client-refresh-token` header. Will take precedence over the current user's
   * refresh token if the {@link AWSAuthenticationMethod.AccountIDAndTokens `AccountIDAndTokens`} {@link IAWSRequestOptions.anonymousAuthenticationFallback authentication mechanism}
   * is specified.
   */
  customRefreshToken?: string;
}

/** Describes a response from an AWS Service. */
export interface IAWSResponse<T = JSONObject> {
  /** Response data. */
  data: T;

  /** Response status code. */
  status: number;

  /** Response status text. */
  statusText: string;

  /** Response headers. */
  headers: unknown;
}

/**
 * Interface to implement AWSService.
 */
export interface IAWSService {
  /**
   * Actually makes the call to AWS, it adds required headers and assembles the call.
   * @param service - The service being called.
   * @param method - The call method configured in the config.
   * @param options - The options object used to assemble the call.
   * @returns An AWS response for this specific service.
   */
  call<S extends ConfiguredService>(
    service: S,
    method: ServiceMethods<S>,
    options?: IAWSRequestOptions
  ): Promise<IAWSResponse>;
}

/**
 * This service will be for handling behavior in AWS that is shared
 * among all or most AWS related services as we interact with them
 * in the Next application.
 */
class AWSService implements IAWSService {
  private suppressMissingSessionIDWarning = false;

  /**
   * Gets the 'aws' from the ConfigurationService or
   * from the backing field.
   * @returns The 'aws' config.
   */
  private get awsConfig(): Config<'aws'> {
    return ConfigurationService.getConfig('aws');
  }

  /**
   * Gets the 'awsServices' from the ConfigurationService or
   * from the backing field.
   * @returns The 'awsServices' config.
   */
  private get awsServicesConfig(): Config<'awsServices'> {
    return ConfigurationService.getConfig('awsServices');
  }

  /**
   * Either gets the client or creates a new one and returns it.
   * @returns An axios client.
   */
  @siteCached
  private get client(): AxiosInstance {
    return axios.create({
      baseURL: this.awsConfig.getSetting('gatewayURL').value,
      httpAgent: new HTTPAgent({ keepAlive: true }),
      httpsAgent: new HTTPSAgent({ keepAlive: true })
    });
  }

  /**
   * CallService is responsible to get AWS auth config and dispatch a request
   * to the service endpoint provided.
   * @param serviceEndpoint - Service endpoint.
   * @param method - HTTP Method.
   * @param options - Request options.
   * @returns Generic AWS response.
   */
  private async callService<R extends object = JSONObject>(
    serviceEndpoint: string,
    method: HTTPMethod,
    options: IAWSRequestOptions = {}
  ): Promise<IAWSResponse<R>> {
    // Gets auth config for the app's role.
    // Sends auth with request given the service endpoint, HTTP method, and params
    // (which will be send are query params or post body, depending on the method.)
    const {
      body,
      params,
      headers,

      endpointParams,
      sessionID: argsSessionID,

      authentication = AWSAuthenticationMethod.AccountID,
      anonymousAuthenticationFallback = AWSAuthenticationMethod.UserID,
      customUserID,
      customAccountID,
      customAccessToken,
      customRefreshToken
    } = options;

    const completedEndpoint = endpointParams
      ? replaceStringVariables(serviceEndpoint, endpointParams)
      : serviceEndpoint;

    const auth = this.awsConfig.getSetting('auth').value;
    const gatewayURL = this.awsConfig.getSetting('gatewayURL').value;
    const url = awsURLTemplate(gatewayURL, completedEndpoint);

    let sessionID: Nullable<string> = null;
    let userID: Nullable<string> = null;
    let accountID: Nullable<string> = null;
    let accessToken: Nullable<string> = null;
    let refreshToken: Nullable<string> = null;

    try {
      // If the session ID was specified in the arguments, just use it and call it a day.
      if (argsSessionID) {
        sessionID = argsSessionID;
      } else {
        try {
          // Try to get the current session and its ID automatically.
          // This will throw if the session ID cookie is not accessible.
          const currentSession = await ServerSessionService.currentSession;
          sessionID = currentSession.id;

          // On every AWS call, try to get the current user and their tokens (if they're
          // logged in) to include in the request headers. This call will also refresh the
          // user's access token if it's expired. If the user is not logged in, or if their
          // tokens couldn't be refreshed, this will return `null`.
          const currentUser =
            await ServerUserService.tryGetAuthorizedUser(currentSession);

          userID =
            // If no authorized user was found, then fall back to using an anonymous user ID
            customUserID ?? currentUser?.uuid ?? ServerUserService.getUserID();

          if (customAccountID) {
            accountID = customAccountID;
          } else if (currentUser) {
            accountID = currentUser.account.id;
          }

          if (customAccessToken) {
            accessToken = customAccessToken;
          } else if (currentUser) {
            accessToken = currentUser.tokens.accessToken;
          }

          if (customAccessToken) {
            refreshToken = customRefreshToken;
          } else if (currentUser) {
            refreshToken = currentUser.tokens.refreshToken;
          }
        } catch (error) {
          if (
            // If the Session ID cookie was not found and build
            // mode is enabled...
            (error instanceof SessionIDCookieNotFoundError ||
              error instanceof UnableToDetermineSessionIDError) &&
            (EnvironmentService.isBuilding || EnvironmentService.isStatic)
          ) {
            // Allow using Build ID as a fallback Session ID since a 'session-id' header is required for all AWS calls.
            sessionID = EnvironmentService.buildID;
            // Fallback to sessionID when in build mode.
            userID = sessionID;

            if (!this.suppressMissingSessionIDWarning) {
              // Warn of the fallback behavior.
              LoggerService.warn(
                `Session ID cookie not found on AWS call to ${url}. Falling back to Build ID. This warning will be auto-suppressed.`
              );

              // Automatically suppress this warning to prevent flooding the console.
              this.suppressMissingSessionIDWarning = true;
            }
          } else {
            throw error;
          }
        }
      }

      if (!sessionID) {
        throw new InvalidStateError(
          `Could not determine session for request${
            (process.env.NEXT_PUBLIC_APP_ENV === "dev") ? ` to "${url}".` : '.'
          }`
        );
      }

      const getAuthHeaders = (
        authMechanism: typeof authentication
      ): {
        'user-id'?: string;
        'account-id'?: string;
        'client-access-token'?: string;
        'client-refresh-token'?: string;
      } => {
        switch (authMechanism) {
          case AWSAuthenticationMethod.None:
            return {};

          case AWSAuthenticationMethod.UserID: {
            if (userID) {
              return {
                'user-id': userID
              };
            }

            // Don't throw if either...
            const dontThrow =
              // A custom session ID was provided in the arguments, since
              // we can't guarantee that the specified session corresponds to the
              // current user and this is probably an administrative operation.
              Boolean(argsSessionID) ||
              // The app is building, since the session ID falls back to the
              // build ID and getting data from the session would yield nothing.
              EnvironmentService.isBuilding;

            if (dontThrow) {
              return {};
            }

            throw new InvalidStateError(
              'Cannot add User ID to AWS request:' +
                " Could not determine the current user's ID from session."
            );
          }

          case AWSAuthenticationMethod.AccountID: {
            if (accountID) {
              return { 'account-id': accountID };
            }

            if (anonymousAuthenticationFallback) {
              return getAuthHeaders(anonymousAuthenticationFallback);
            }

            throw new UserNotAuthenticatedError(
              (process.env.NEXT_PUBLIC_APP_ENV === "dev")
                ? `Cannot make an authenticated call to AWS endpoint ${serviceEndpoint} since the current user is not authenticated.`
                : 'The requested call cannot be completed since the current user is not authenticated.'
            );
          }

          case AWSAuthenticationMethod.AccountIDAndToken: {
            if (accountID && accessToken) {
              return {
                'account-id': accountID,
                'client-access-token': accessToken
              };
            }

            if (anonymousAuthenticationFallback) {
              return getAuthHeaders(anonymousAuthenticationFallback);
            }

            throw new UserNotAuthenticatedError(
              (process.env.NEXT_PUBLIC_APP_ENV === "dev")
                ? `Cannot make an authenticated call to AWS endpoint ${serviceEndpoint} since the current user is not authenticated.`
                : 'The requested call cannot be completed since the current user is not authenticated.'
            );
          }

          case AWSAuthenticationMethod.AccountIDAndTokens: {
            if (accountID && accessToken && refreshToken) {
              return {
                'account-id': accountID,
                'client-access-token': accessToken,
                'client-refresh-token': refreshToken
              };
            }

            if (anonymousAuthenticationFallback) {
              return getAuthHeaders(anonymousAuthenticationFallback);
            }

            throw new UserNotAuthenticatedError(
              (process.env.NEXT_PUBLIC_APP_ENV === "dev")
                ? `Cannot make an authenticated call to AWS endpoint ${serviceEndpoint} since the current user is not authenticated.`
                : 'The requested call cannot be completed since the current user is not authenticated.'
            );
          }

          default: {
            throw new InvalidArgumentError(
              'Cannot get AWS auth headers: ' +
                `Received invalid AWSAuthentication Method "${authMechanism}".`
            );
          }
        }
      };

      const requestConfig: AxiosRequestConfig = {
        url,
        params,
        method,
        headers: {
          'Accept-Encoding': 'gzip, deflate, br',
          'x-api-key': `${auth}`,
          'site-id': EnvironmentService.brand.toLowerCase(),
          'build-id': EnvironmentService.buildID,
          'app-instance': CurrentRequestService.appInstanceID,
          'app-env': (process.env.NEXT_PUBLIC_APP_ENV),
          'app-build-type': (process.env.IS_DEVELOPMENT_MODE ? "dev" : "prod"),
          'site-locale': I18NService.currentLocale.toString(),
          'site-region': EnvironmentService.region,
          'frontend-id': 'headless-frontend',
          'session-id': sessionID,
          timestamp: new Date().toISOString(),

          // Spread authentication headers.
          ...getAuthHeaders(authentication),

          // Spread the headers provided with the request options.
          ...headers
        },
        data: body
      };

      // Uncomment to see AWS request as sent
      // console.debug('request', JSON.stringify(requestConfig));

      const response = await this.client.request<JSONObject>(requestConfig);

      // Uncomment to see AWS response
      // console.debug('response', JSON.stringify(response));

      return {
        data: response.data as unknown as R,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers
      };
    } catch (e) {
      // Only handle if this error is an Axios error.
      if ((e as AxiosError).isAxiosError) {
        const axiosError = e as AxiosError;
        const request = axiosError.request as ClientRequest;
        const { response } = axiosError;

        // Uncomment to see AWS error response
        // console.debug('response', response);

        const baseErrMsg = `Request error ocurred when trying calling AWS Service "${request.path}".`;

        if (!response) {
          throw new RequestError(baseErrMsg, {
            cause: axiosError
          });
        }

        const responseDataMsg = `Error: \n${JSON.stringify(
          response.data,
          null,
          2
        )}`;

        switch (response.status) {
          case 404: {
            throw new ResourceNotFoundError(
              `${baseErrMsg} ${responseDataMsg}`,
              { cause: axiosError }
            );
          }
          case 409: {
            throw new RequestConflictError(`${baseErrMsg} ${responseDataMsg}`, {
              cause: axiosError
            });
          }
          // TODO: handle more specific error codes.
        }

        throw new RequestError(`${baseErrMsg} ${responseDataMsg}`, {
          cause: axiosError
        });
      }

      throw e;
    }
  }

  /**
   * Call is responsible to assemble service configs and and dispatch call service request.
   * @param service - AWS Service.
   * @param method - AWS service method.
   * @param options - Request options.
   * @returns Generic AWS response.
   */
  public async call<S extends ConfiguredService, R extends object = JSONObject>(
    service: S,
    method: ServiceMethods<S>,
    options?: IAWSRequestOptions
  ): Promise<IAWSResponse<R>> {
    const awsService = service as keyof AWSServices;
    const awsMethod = method as AllServicePossibleMethods;

    // get config values from config service for provided service.
    /* eslint-disable @typescript-eslint/no-unsafe-member-access -- Temporary solution for config model depth issues. */
    /* eslint-disable @typescript-eslint/no-unsafe-call -- Temporary solution for config model depth issues. */
    const methodsConfig = (this.awsServicesConfig.getSetting as any)(
      `${awsService}.methods` as const
    ).value;

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Disabling
     * non-null assertion warning here because we already check that `awsMethod` is indeed
     * a property of `methodsConfig`, otherwise we throw. */
    const methodDetails = methodsConfig[awsMethod]!.value;
    const endpoint = methodDetails.endpoint.value;
    const httpMethod = methodDetails.httpMethod.value;

    const authentication = methodDetails.authentication?.value;
    const anonymousAuthenticationFallback =
      methodDetails.anonymousAuthenticationFallback?.value;

    return this.callService<R>(endpoint, httpMethod, {
      authentication,
      anonymousAuthenticationFallback,

      ...options
    });
  }
}

export default new AWSService();
