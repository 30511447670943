import { Span, SpanStatusCode, trace } from '@opentelemetry/api';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { Nullable } from '@/type-utils';
import ConfigurationService from '../../../isomorphic/ConfigurationService';

import Service from '../../../Service';

/**
 * A list of tags for adding context to telemetry data to help
 * filtering data across different services and environment.
 * - 'env': A primary tag for current environment (ex. Dev, stg, prod, ...).
 * - 'brand': A custom tag for current brand (ex. Ahnu, teva, ugg, ...).
 * - 'platform': A custom tag for platform.
 *
 * Add keys to this type for additional tags as needed.
 */
type TagKey = 'env' | 'brand' | 'platform';

/**
 * Wrapper class for Vercel OpenTelemetry Collector Vercel OpenTelemetry (OTEL) collector
 * allows us to send OTEL traces from Serverless functions
 * to Application performance monitoring (APM) in DataDog.
 *
 * @see https://vercel.com/docs/observability/otel-overview
 *
 * This wrapper class provides methods to start, end,
 * and manage spans (units of work) with appropriate tagging and error handling.
 *
 *
 */
class OTelService extends Service {
  /**
   * Starts a new span for the given function name, adds mandatory and additional tags,
   * and returns the created span.
   * @param functionName - Function name to trace.
   * @param tags - {@link TagKey Tag} Record for tagging.
   * @returns The OTel time {@link Span span} that was started.
   */
  public startSpan(
    functionName: string,
    tags: Nullable<Record<TagKey, string>>
  ): Span {
    const otelConfig = ConfigurationService.getConfig('otel');
    const serviceName = otelConfig.getSetting('serviceName').value;
    const tracer = trace.getTracer(serviceName);
    const span: Span = tracer.startSpan(functionName);

    try {
      // Add mandatory tags to span.
      span.setAttribute('env', (process.env.NEXT_PUBLIC_APP_ENV));
      span.setAttribute('brand', otelConfig.getSetting('brand').value);
      span.setAttribute('platform', otelConfig.getSetting('platform').value);

      // Add additional tags to span.
      if (tags) {
        for (const [key, value] of Object.entries(tags)) {
          span.setAttribute(key, value);
        }
      }
    } catch (error) {
      this.setSpanError(span, error);
    }

    return span;
  }

  /**
   * Ends the given span and handles any errors that may occur during the process.
   * @param span - {@link Span} to be ended.
   */
  public endSpan(span: Span): void {
    try {
      span.end();
    } catch (error) {
      this.setSpanError(span, error);
    }
  }

  /**
   * Sets an error on the given span, records the exception, and updates the span status to error.
   * @param span - {@link Span} to set the error on.
   * @param error - Error to record.
   */
  private setSpanError(span: Span, error: unknown): void {
    const errorMessage = error instanceof Error ? error.message : String(error);
    span.recordException(errorMessage);
    span.setStatus({
      code: SpanStatusCode.ERROR,
      message: errorMessage
    });
  }
}

export default new OTelService();
