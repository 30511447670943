import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { UnableToModifyResourceError } = errorFactory(
  'UnableToModifyResourceError',
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/** Error to use when a resource could not be modified. */
export type UnableToModifyResourceError = InstanceTypeOf<
  typeof UnableToModifyResourceError
>;
