import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { ForbiddenError } = errorFactory('ForbiddenError', {
  mapsToHTTPError: 'ForbiddenError'
} as IHTTPErrorMetaData);

/** Generic Error to use when an operation fails due to insufficient access privileges to a resource. */
export type ForbiddenError = InstanceTypeOf<typeof ForbiddenError>;
