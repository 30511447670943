import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { UnauthorizedError } from '@/utils/errors';

export const { UserNotAuthenticatedError } = errorFactory(
  'UserNotAuthenticatedError',
  UnauthorizedError,
  {
    mapsToHTTPError: 'UnauthorizedError'
  } as IHTTPErrorMetaData
);

/**
 * Error to use when trying to do an operation that requires a user to be authenticated fails
 * due to the user not being authenticated.
 */
export type UserNotAuthenticatedError = InstanceTypeOf<
  typeof UserNotAuthenticatedError
>;
