import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { InvalidCognitoAccessTokenError } from './InvalidCognitoAccessTokenError';

export const { CognitoAccessTokenExpiredError } = errorFactory(
  'CognitoAccessTokenExpiredError',
  InvalidCognitoAccessTokenError
);

/** Error that indicates that a Cognito Access Token is expired. */
export type CognitoAccessTokenExpiredError = InstanceTypeOf<
  typeof CognitoAccessTokenExpiredError
>;
