import { SESSION_ID_KEY } from '@/configs/env/public';
import MemoryCache from '@/utils/MemoryCache';
import { HTTP } from '@/utils/api-utils/http-statuses';
import { ServerCodeInClientError } from '@/utils/errors';
import { SessionModel } from '../../models/Session';

import Service from '../../Service';
import CookieService from '../../isomorphic/CookieService';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';
import AWSSessionService from '../integrations/AWS/AWSSessionService';

// import { siteCached } from '@/services/utils/siteCached/siteCached';
// import ConfigurationService, { Config } from '../../isomorphic/ConfigurationService';
import {
  SessionIDCookieNotFoundError,
  UnableToDetermineSessionIDError
} from '../../isomorphic/SessionService';
import { CookieNotFoundError } from '../../models/Cookie';

/**
 * Server-side abstraction service for session-related operations.
 * For the isomorphic service, see {@link SessionService}.
 */
class ServerSessionService extends Service {
  // /**
  //  * The session config.
  //  * @returns A `Config<'session'>`.
  //  */
  // @siteCached
  // private get sessionConfig(): Config<'session'> {
  //   return ConfigurationService.getConfig('session');
  // }

  private sessionModelCache = new MemoryCache<SessionModel>();

  /**
   * Returns the Session ID present in the cookie.
   *
   * @returns The Session ID.
   *
   * @throws A {@link SessionIDCookieNotFoundError} if the Session ID cookie is not present.
   * @throws An {@link UnableToDetermineSessionIDError} if the Session ID cannot be
   * determined for some unknown reason.
   */
  public getSessionID(): string {
    try {
      return CookieService.get(SESSION_ID_KEY).toString();
    } catch (error) {
      if (error instanceof CookieNotFoundError) {
        // The session ID cookie was not found.
        throw new SessionIDCookieNotFoundError(
          `Could not get the session ID since its cookie was not found.`,
          { cause: error }
        );
      }

      throw new UnableToDetermineSessionIDError(
        'An unexpected error occurred when trying to determine the current Session ID.',
        { cause: error as Error }
      );
    }
  }

  /**
   * Ends the current session and redirects to a new page.
   * Can only be called on the server.
   * @param route - The route to redirect to.
   * @throws A {@link ServerCodeInClientError} if used on client.
   * @throws A {@link HTTP.MovedTemporarilyStatus} to trigger a redirect.
   */
  public endSession(route: string): void {
    if ((typeof window !== "undefined")) {
      throw new ServerCodeInClientError(
        'The session can not be ended on the client side'
      );
    }

    this.sessionModelCache.remove(this.getSessionID());
    throw new HTTP.MovedTemporarilyStatus(route);
  }

  /**
   * Retrieves the current session for this request.
   * If the session doesn't exist in the local memory cache, retrieves it from the
   * persistent session storage.
   *
   * @returns A session DTO representing the current session.
   */
  public get currentSession(): Promise<SessionModel> {
    return (async () => {
      const id = this.getSessionID();

      /**
       * TODO: Caching is disabled indefinitely as the current implementation
       * may create and cache different cart IDs if our API is distributed across
       * server multiple instances.
       *
       * A refactor is needed either in Next or AWS before this can be re-enabled.
       */
      const cacheEnabled = false;
      // const cacheEnabled = this.sessionConfig.getSetting('enableCache').value;

      if (cacheEnabled) {
        if (!this.sessionModelCache.has(id)) {
          // Create
          const sessionData = await AWSSessionService.getSession(id);
          this.sessionModelCache.add(id, SessionModel.from(sessionData));
        }

        return this.sessionModelCache.get(id);
      }

      return SessionModel.from(await AWSSessionService.getSession(id));
    })();
  }

  /**
   * Sets the session data in the server's memory cache and in the remote session storage
   * for persistence.
   *
   * @param key - The key to store the data at.
   * @param value - The value to store.
   * @param [secure] - Denotes whether this data should exist in the `.data` or `.secureData` map.
   * @param [ttl] - Time-to-live in seconds (Default is Infinity).
   * @throws {@link ServerCodeInClientError} When called on the client.
   * @returns `SessionModel` representing the current session.
   */
  public async setSessionData(
    key: string,
    value: string,
    secure: boolean = false,
    ttl: number = Infinity
  ): Promise<SessionModel> {
    if (!(typeof window === "undefined")) {
      throw new ServerCodeInClientError(
        'Setting session data is only allowed on the server.'
      );
    }

    const currentSession = await this.currentSession;
    const dataModel = secure ? currentSession.secureData : currentSession.data;

    // Step 1: Add value to local session cache.
    dataModel.set(key, value, ttl);

    // Step 2: Push value to the AWS Session Service.
    await AWSSessionService.pushSessionEvent(currentSession);

    return currentSession;
  }

  /**
   * Removes a value from session.
   *
   * @param key - The key to store the data at.
   * @param [secure] - Denotes whether this data should be removed from the
   * `.data` or the `.secureData` map.
   *
   * @throws A {@link ServerCodeInClientError} when called on the client.
   * @returns `SessionModel` representing the current session.
   */
  public async removeSessionData(
    key: string,
    secure: boolean = false
  ): Promise<SessionModel> {
    if (!(typeof window === "undefined")) {
      throw new ServerCodeInClientError(
        'Setting session data is only allowed on the server.'
      );
    }

    const currentSession = await this.currentSession;
    const dataModel = secure ? currentSession.secureData : currentSession.data;

    // Step 1: Remove the value from the local session.
    dataModel.remove(key);

    // Step 2: Push value to the AWS Session Service.
    await AWSSessionService.pushSessionEvent(currentSession);

    return currentSession;
  }
}

export default new ServerSessionService();
