import { DTO, TupleTypeToUnionType } from '@/type-utils';
import { ForbiddenActionError } from '@/utils/errors';

import Model from '../Model';
import { INormalizedResponse } from './interfaces/INormalizedResponse';
import { AnyRoute, RouteType } from './interfaces/RouteType';

/** @inheritdoc */
export default class NormalizedResponseModel<T extends RouteType = AnyRoute>
  extends Model<DTO<INormalizedResponse<T>>>
  implements INormalizedResponse
{
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly routeType: INormalizedResponse<T>['routeType'];

  /** The raw response object. */
  public readonly rawResponse: INormalizedResponse<T>['rawResponse'];

  /** @inheritdoc */
  public readonly custom: INormalizedResponse<T>['custom'];

  /**
   * @inheritdoc
   * @param response - A `DTO` of {@link INormalizedResponse}.
   */
  public constructor(response: DTO<INormalizedResponse>) {
    super(response);

    this.id = response.id;
    this.routeType = response.routeType;
    this.rawResponse = response.rawResponse as this['rawResponse'];
    this.custom = new Map(response.custom);
  }

  /** @inheritDoc */
  public isRouteType<T extends RouteType>(
    routeType: T
  ): this is NormalizedResponseModel<T> {
    return this.routeType === routeType;
  }

  /** @inheritDoc */
  public isAnyRouteType<T extends Array<RouteType>>(
    ...routeTypes: T
  ): this is NormalizedResponseModel<TupleTypeToUnionType<T>> {
    return routeTypes.some((routeType) => routeType === this.routeType);
  }

  /**
   * Returns true if the current request is any type of request for which
   * the `response` object extends the `ServerResponse` class. This is used because
   * there are 3 types of requests that extend it and have the same API to interact
   * with the response.
   *
   * @returns Boolean.
   */
  private extendsServerResponseClass(): this is NormalizedResponseModel<
    RouteType.API | RouteType.InitialPage | RouteType.ServerPage
  > {
    return this.isAnyRouteType(
      RouteType.API,
      RouteType.InitialPage,
      RouteType.ServerPage
    );
  }

  /** @inheritDoc */
  public setHeader(name: string, value: string | ReadonlyArray<string>): this {
    if (this.isRouteType(RouteType.StaticPage)) {
      throw new ForbiddenActionError(
        'Setting headers for static pages is not supported!'
      );
    }

    if (this.extendsServerResponseClass()) {
      this.rawResponse.setHeader(name, value);
    }

    if (this.isRouteType(RouteType.NextMiddleware)) {
      this.rawResponse.headers.set(name, value.toString());
    }

    return this;
  }

  /** @inheritDoc */
  public getHeader(name: string): string | Array<string> | undefined {
    if (this.isRouteType(RouteType.StaticPage)) {
      throw new ForbiddenActionError(
        'Getting headers for static pages is not supported!'
      );
    }

    if (this.extendsServerResponseClass()) {
      const value = this.rawResponse.getHeader(name);
      if (typeof value === 'number') {
        return value.toString();
      }

      return value;
    }

    if (this.isRouteType(RouteType.NextMiddleware)) {
      const value = this.rawResponse.headers.get(name);
      if (value === null) {
        return undefined;
      }

      return value;
    }

    return undefined;
  }

  /** @inheritDoc */
  public deleteHeader(name: string): void {
    if (this.isRouteType(RouteType.StaticPage)) {
      throw new ForbiddenActionError(
        'Deleting headers for static pages is not supported!'
      );
      // if in production mode, the line below will run anyway
      return;
    }

    if (this.extendsServerResponseClass()) {
      this.rawResponse.removeHeader(name);
    }

    if (this.isRouteType(RouteType.NextMiddleware)) {
      this.rawResponse.headers.delete(name);
    }
  }

  /** @inheritDoc */
  public hasHeader(name: string): boolean {
    if (this.isRouteType(RouteType.StaticPage)) {
      throw new ForbiddenActionError(
        'Checking for headers for static pages is not supported!'
      );
    }

    if (this.extendsServerResponseClass()) {
      return this.rawResponse.hasHeader(name);
    }

    if (this.isRouteType(RouteType.NextMiddleware)) {
      return this.rawResponse.headers.has(name);
    }

    return false;
  }

  /** @inheritDoc */
  public appendHeader(
    name: string,
    value: string | ReadonlyArray<string>
  ): void {
    if (this.isRouteType(RouteType.StaticPage)) {
      throw new ForbiddenActionError(
        'Checking for headers for static pages is not supported!'
      );
    }

    if (this.extendsServerResponseClass()) {
      /**
       * The challenge with this one is that ServerResponse class doesn't allow appending headers
       * however the NextMiddleware does. So for ServerResponse, we have to retrieve the current value,
       * push a new one and set the header with a new value (array).
       */
      let values: Array<string> = [];

      if (this.hasHeader(name)) {
        const currentValue = this.getHeader(name);
        if (currentValue instanceof Array) {
          values = [...currentValue];
        } else if (typeof currentValue === 'string') {
          values.push(currentValue);
        }
      }

      // The provided new value, append it at the end to keep the order.
      // Maybe it matters? Like setting a cookie and then deleting a cookie would
      // result in 2 headers and the order would matter.
      if (value instanceof Array) {
        for (const val of value) {
          values.push(val);
        }
      } else {
        values.push(value);
      }

      this.rawResponse.setHeader(name, values);
      // Return early to avoid an unnecessary if statement below
      return;
    }

    // For NextMiddleware, it's much simpler since it has the `.append` API.
    if (this.isRouteType(RouteType.NextMiddleware)) {
      const values = value instanceof Array ? value : [value];
      for (const val of values) {
        this.rawResponse.headers.append(name, val);
      }
    }
  }

  /** @inheritdoc */
  public toDTO(): DTO<INormalizedResponse> {
    const { id, routeType, rawResponse, custom } = this;

    return {
      id,
      routeType,
      rawResponse,
      custom
    };
  }
}
