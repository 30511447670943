/** Represents the type of route a middleware function is being called on. */
export enum RouteType {
  /** The middleware is running against a route in `/pages/api/`. */
  API,

  /** The middleware is running against a route using `getStaticProps`. */
  StaticPage,

  /** The middleware is running against a `getStaticPaths` handler function. */
  StaticPaths,

  /** The middleware is running against a route using `getServerSideProps`. */
  ServerPage,

  /** The middleware is running against a route using `getInitialProps`. */
  InitialPage,

  /** The middleware is running against Next's built-in request middleware. */
  NextMiddleware
}

/** Represents any possible route. */
export type AnyRoute =
  | RouteType.API
  | RouteType.NextMiddleware
  | RouteType.ServerPage
  | RouteType.StaticPaths
  | RouteType.StaticPage
  | RouteType.InitialPage;
